import React, {useEffect, useState, useContext} from "react"

import "./confirmEmail.css"

import useExecute from "hooks/useExecute"
import { apiCaller } from "api/apiCaller"
import TextField from "components/inputs/textfieldUnderline/textfieldUnderline"
import { AuthUserContext } from "../../authentication"
import { CartContext } from "pages/user/cart/userCart"
import { AuthContext } from "context/authProvider"
import { Axios, AxiosError, AxiosResponse } from "axios"

export default function ConfirmEmail(props: {onWaitResponse: Function}){

    const authContext     = useContext(AuthContext)
    const cartContext     = useContext(CartContext)
    const authUserContext = useContext(AuthUserContext)

    const [code,  setCode ] = useState<string>("")
    const [error, setError] = useState<string>("")

    useExecute(4.6, error, handleResetError)
    
    function handleCodeChange(code: string) {
        setCode(code)
    }
    
    function handleCodeConfirm(){
        if (!code){
            setError("Código inválido")
        } else {

            let triggerAnimation: boolean = true
    
            setTimeout(() => {
                if (triggerAnimation){
                    props.onWaitResponse(true, "emailConfirm")
                }
            }, 100)
    
            apiCaller.post("/auth/confirm", 
                {
                    email_confirmation_code: code
                },
                {
                    params: {
                        email: authUserContext?.user.email
                    }
                }
            ).then((response: AxiosResponse) => {
                apiCaller.post("/auth/login",
                    {
                        email: authUserContext?.user.email,
                        password: authUserContext?.user.password
                    }
                ).then((response: AxiosResponse) => {
                    authContext?.setToken(response.data.data)
                    
                    cartContext?.setStatusTracker((prevStatus) => ({
                        ...prevStatus,
                        address: true
                    }))
                    
                    cartContext?.handleNavigation("address")
                }).catch((error: AxiosError) => {
    
                    if (triggerAnimation) {
                        props.onWaitResponse(false, "login")
                        triggerAnimation = false
                        console.log("ai lascou mermo")
                        console.log("erro", error)
                    }
                })
    
            }).catch((error: AxiosError) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "emailConfirm")
                    triggerAnimation = false
                    setError("Código inválido")
                }
            })
        }
    }
    
    function handleResendCode(){
        apiCaller.post("/auth/resend", 
            {

            },
            {
                params: {
                    email: authUserContext?.user.email
                }
            }
        ).then((response: AxiosResponse) => {
            
        }).catch((error: AxiosError) => {

        })
    }
    
    function handleResetError(){
        setError("")
    }

    return (
        <div id="cart-authentication-confirm-email-main">
            <div id="cart-authentication-confirm-email-input">
                <TextField
                    label="Código"
                    value={code}                    
                    onValueChange={handleCodeChange}
                    onSelected={handleResetError}
                />
            </div>
            <div id="cart-authentication-confirm-email-adversiment">
                {
                    error &&
                    (
                        <p id="cart-authentication-confirm-email-adversiment-txt">
                            {error}
                        </p>
                    )
                }
            </div>
            <div id="cart-authentication-confirm-email-resend-timer">

            </div>
            <div 
                id="cart-authentication-confirm-email-confirm-btn"
                onClick={handleCodeConfirm}
            >
                <p 
                    id="cart-authentication-confirm-email-confirm-btn-txt"
                >
                    Confirmar
                </p>
            </div>
            <p 
                id="cart-authentication-confirm-email-resend-btn"
                onClick={handleResendCode}
            >
                Reenviar código
            </p>
        </div>
    )
}