import React, {useState, useEffect, useContext} from "react"

import "./checkout.css"

import UserProfileCartCard    from "./components/userProfileCartCard"
import { apiCaller } from "api/apiCaller"
import { CartContext }        from "pages/user/cart/userCart"
import { CartStorageContext } from "context/cartStorageProvider"
import { AuthContext }        from "context/authProvider"
import { AxiosError, AxiosResponse } from "axios"
import { useNavigate } from "react-router-dom"
import { IProduct } from "interfaces/product/IProduct"

export default function CartCheckout(props: {onCheckout?: Function}){

    const authContext        = useContext(AuthContext)
    const cartStorageContext = useContext(CartStorageContext)
    const cartContext        = useContext(CartContext)

    const navigate = useNavigate()

    const [products, setProducts] = useState<{quantity: number, data: IProduct}[]>([])
    const [total, setTotal] = useState<string>("")

    useEffect(() => {
        cartContext?.setStatusTracker((prevStatus) => ({
            ...prevStatus,
            checkout: true
        }))

        cartStorageContext?.updateProducts()
        setProducts(cartStorageContext?.getProducts())
    },[cartStorageContext?.productQuantity])
    
    useEffect(() => {
        const totalPrice = products.reduce((accumulator, product) => {
            if (product.data.variations[0].promotionEnabled){
                return accumulator + (product.data.variations[0].promotionPrice * product.quantity)
            } else {
                return accumulator + (product.data.variations[0].price * product.quantity)
            }
        }, 0)

        setTotal(totalPrice.toFixed(2))
    },[products])

    function handleCheckout(){

        if (!authContext?.isAuthenticated){
            cartContext?.handleNavigation("auth")

            cartContext?.setStatusTracker((prevStatus) => ({
                ...prevStatus,
                auth: true
            }))
        } else {
            apiCaller.get("/accounts/", {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {

                let cartProducts = cartStorageContext?.getProducts()
                let parsedCartProducts = cartProducts.map((product: any) => {
                    return {
                        quantity: product.quantity,
                        sizeId: product.data.variations[0].sizes[0].id
                    }
                })

                cartContext?.setOrder((prev) => ({
                    ...prev,
                    products: parsedCartProducts
                }))

                cartContext?.handleNavigation("address")

                cartContext?.setStatusTracker((prevStatus) => ({
                    ...prevStatus,
                    auth: true,
                    address: true
                }))

            }).catch((error: AxiosError) => {
                authContext?.rmToken()
                navigate("/")
            })
        }
    }
    
    function handleDelete(id: string, idVariation: string, size: string){
        const filteredProducts = products.filter(product => {
            if (product.data.id !== id || product.data.variations[0].id !== idVariation || product.data.variations[0].sizes[0].size !== size){
                return product
            }
        })

        setProducts(filteredProducts)
    }

    return (
        <div id="cart-checkout-main">
            {   
                products.length >= 1 &&
                (
                    <>
                        <div id="cart-checkout-primary">
                            <div id="cart-checkout-product-list-btns">

                            </div>
                            <ul id="cart-checkout-product-list">
                                {
                                    products.map(product => {
                                        return (
                                            <UserProfileCartCard
                                                key={`${product.data.id} ${product.data.variations[0].id} ${product.data.variations[0].sizes[0].size}`}
                                                product={product}
                                                onDelete={handleDelete}
                                            />
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div id="cart-checkout-content-secondary">
                            <div id="cart-checkout-content-sub-total">
                                <div id="cart-checkout-content-subtotal-info-container">
                                    <p id="cart-checkout-content-sub-total-info-label-txt">
                                        Sub Total
                                    </p>
                                    <p id="cart-checkout-content-sub-total-info-value-txt">
                                        R$ {total.replace(".", ",")}
                                    </p>
                                </div>
                                <div id="cart-checkout-content-subtotal-btn-container">
                                    {
                                        !authContext?.isAdministrator &&
                                        (
                                            <div id="cart-checkout-content-sub-total-btn" onClick={handleCheckout}>
                                                <p id="cart-checkout-content-sub-total-btn-txt">
                                                    Fechar Pedido
                                                </p>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                products.length < 1 &&
                (
                    <div id="cart-checkout-empty-cart">
                        <p id="cart-checkout-empty-cart-message-txt">
                            Seu carrinho está vazio
                        </p>
                    </div>
                )
            }
        </div>
    )
}