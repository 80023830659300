import React, { useEffect, useState } from "react"

import "./adminPageTemplate.css"

import Header                    from "components/header/header"
import UserMenuNavigationElement from "components/userMenuNavigationElement/userMenuNavigationElement"
import IAdminPageTemplate from "interfaces/admin/IAdminPageTemplate"

export default function AdminPageTemplate(props: IAdminPageTemplate): JSX.Element {

    const [pathList, setPathList] = useState<string[]>([])

    useEffect(() => {
        parsePath(props.navigationUrl)
    },[])

    function parsePath(path: string){
        const splitedPath = path.split("/")

        const list = []
        let fullPath = ""

        for (let index = 0; index < splitedPath.length; index++) {
            if (index !== 0) {
                fullPath = fullPath + "/" + splitedPath[index]
                list.push(fullPath)            
            } else {
                list.push("/") 
            }
        }
        setPathList(list)
    }

    return (
        <>  
            <Header/>
            <div id="admin-page-template-main">
                <div id="admin-page-template-content">
                    <div id="admin-page-template-header">
                        <div id="admin-page-template-header-img-container">
                            {/* <img 
                                id="admin-page-template-header-img"
                                src={props.image}
                                alt="" 
                                draggable={false}
                            /> */}
                        </div>
                        <div id="admin-page-template-header-title-container">
                            {/* <p id="admin-page-template-header-title-txt">
                                {props.name}
                            </p> */}
                        </div>
                        <ul id="admin-page-template-header-navigation-container">
                            {
                                pathList.map(path => {
                                    if (path === pathList[pathList.length-1]){
                                        return (
                                            <UserMenuNavigationElement key={path} data={{url: path, last: true}}/>
                                        )
                                    } else {
                                        return (
                                            <UserMenuNavigationElement key={path} data={{url: path, last: false}}/>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div id="admin-page-template-list">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}