import React, { useState, useEffect, createContext, useContext, Dispatch } from "react"

import "./userCart.css"

import UserProfileTemplate from "../components/userPageTemplate/userPageTemplate"
import StatusTracker       from "./components/statusTracker/statusTracker"
import Checkout            from "./components/checkout/checkout"
import Authentication      from "./components/authentication/authentication"
import Address             from "./components/address/address"
import Payment             from "./components/payment/payment"
import Result              from "./components/result/result"
import LoadingAnimation from "components/animations/loadingAnimation"
import CartImage           from "assets/icons/pages/userProfile/cart-withouth-background.png"
import { AuthContext }     from "context/authProvider"
import useQuery from "hooks/useQuery"
import IStatusTracker      from "interfaces/cart/IStatusTracket"
import IShipmentAddress    from "interfaces/IShipmentAddress"
import IClient             from "interfaces/IClient"
import { IOrder }          from "interfaces/cart/IOrder"
import { useNavigate } from "react-router-dom"

interface INavigation {
    checkout: boolean
    auth:     boolean
    address:  boolean
    payment:  boolean
    result:   boolean
}

export interface ICartContext {
    order: IOrder,
    setOrder: Dispatch<React.SetStateAction<IOrder>>
    setStatusTracker: Dispatch<React.SetStateAction<IStatusTracker>>
    handleNavigation: Function
}

export const CartContext = createContext<ICartContext | undefined>(undefined);

export default function UserCart(){

    const authContext = useContext(AuthContext)
    
    const query = useQuery()
    const navigate = useNavigate()

    const [path, setPath] = useState<string>("")
    const [result, setResult] = useState<boolean>(false)

    const [navigationController, setNavigationController] = useState<INavigation>({
        checkout: true,
        auth:     false,
        address:  false,
        payment:  false,
        result:   false
    })

    const [statusTracker, setStatusTracker] = useState<IStatusTracker>({
        checkout: false,
        auth:     false,
        address:  false,
        payment:  false,
        result:   false
    })

    const [order, setOrder] = useState<IOrder>({
        addressId: "",
        products: []
    })

    useEffect(() => {
        const statusParameter = query.get("status")
        if (statusParameter) {
            if (!authContext?.getToken()){
                navigate("/")
            }

            if (statusParameter === "fail"){
                setResult(false)
            } 
            else if (statusParameter === "success"){
                setResult(true)
            }
            else {
                navigate("/404")
            }

            setStatusTracker(
                {
                    checkout: true,
                    auth:     true,
                    address:  true,
                    payment:  true,
                    result:   true
                }
            )
            
            handleNavigation("result")
        }
    },[])

    useEffect(() => {
        if (authContext?.isAuthenticated) {
            setPath("/perfil/carrinho")
        } else {
            setPath("/carrinho")
        }
    },[authContext?.isAuthenticated])

    function handleNavigation(element: string){
        for (const key in navigationController){
            if (navigationController.hasOwnProperty(key)){
                const keyTyped = key as keyof INavigation

                if (keyTyped === element){
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    return (
        <CartContext.Provider value={
            {
                order: order,
                setOrder: setOrder,
                setStatusTracker: setStatusTracker,
                handleNavigation: handleNavigation
            }
        }
        >
            <UserProfileTemplate image={CartImage} name="Carrinho" navigationUrl={path} key={"cart"}>
                <div id="user-profile-cart-status-tracker">
                    {
                        !authContext?.isAdministrator &&
                        (
                                <StatusTracker data={statusTracker}/>
                        )
                    }
                </div>
                {
                    navigationController.checkout &&
                    (
                        <Checkout/>
                    )
                }
                {
                    navigationController.auth &&
                    (
                        <Authentication/>
                    )
                }
                {   
                    navigationController.address &&
                    (
                        <Address/>
                    )
                }
                {   
                    navigationController.payment &&
                    (
                        <Payment/>
                    )
                }
                {
                    navigationController.result && 
                    (
                        <Result success={result}/>
                    )
                }
            </UserProfileTemplate>
        </CartContext.Provider>
    )
}