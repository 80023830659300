import React from "react"
import { Link } from "react-router-dom"

import "./userMenuNavigationElement.css"

import Chevron1 from "assets/icons/navigation/nav-chevron1.svg"
import Chevron2 from "assets/icons/navigation/nav-chevron2.png"
import Chevron3 from "assets/icons/navigation/nav-chevron3.png"

import IUserMenuNavElement from "interfaces/IUserMenuNavElement"

export default function UserMenuNavigationElement(props: {data: IUserMenuNavElement}){

    function parseName(){
        const name = props.data.url.split("/")

        if (name[name.length-1] === ""){
            return "pagina principal"
        } else {
            return name[name.length-1]
        }
    }

    function parseUrl(){
        if (props.data.prefix){
            return props.data.prefix + props.data.url
        } else {
            return props.data.url
        }
    }

    return (
        <li id="user-menu-navigation-element-main">
            <Link id="user-menu-navigation-element-link" to={parseUrl()} draggable={false}>
                <p id="user-menu-navigation-element-link-txt">
                    {parseName()}
                </p>
            </Link>
            <div id="user-menu-navigation-element-img-container">
                <img
                    id="user-menu-navigation-element-img" 
                    style={props.data.last ? {display: "none"}: {display: "flex"}}
                    src={Chevron1} 
                    alt="" 
                    draggable={false}
                />
            </div>
        </li>
    )
}