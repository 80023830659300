import React, {useRef, useState, useContext} from "react"

import "./newBanner.css"

import TextInput from "components/textInput/textInput"
import { BannerConfigurationContext } from "../../mainPage"
import PlaceholderImage from "assets/backgrounds/banner-placeholder.svg"

export default function NewBanner(){

    const bannerConfigurationContext = useContext(BannerConfigurationContext)

    const imageInputBtnRef = useRef<HTMLInputElement>(null)


    function handleTriggerUpload(){
        if (imageInputBtnRef.current){
            imageInputBtnRef.current.click()
        }
    }

    function handleImageUpload(files: any){
        const file: File = files[0];
        
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                if (typeof base64String === "string") {
                    bannerConfigurationContext?.setSelectedBanner((prev) => {
                        return {
                            ...prev,
                            content: base64String
                        }
                    })
                }
            }

            reader.readAsDataURL(file);
        }
    }

    function handleNameChange(value: string){
        bannerConfigurationContext?.setSelectedBanner((prev) => {
            return {
                ...prev,
                filename: value
            }
        })
    }

    function handleUrlChange(value: string){
        bannerConfigurationContext?.setSelectedBanner((prev) => {
            return {
                ...prev,
                url: value
            }
        })
    }


    return (
        <div id="admin-settings-catalog-banners-new">
            <div className="admin-settings-catalog-banners-new-info-input-container">
                <TextInput
                    label="Nome"
                    onValueChange={handleNameChange}
                />
            </div>
            <div className="admin-settings-catalog-banners-new-info-input-container">
                <TextInput
                    label="URL"
                    onValueChange={handleUrlChange}
                />
            </div>
            <div id="admin-settings-catalog-banners-new-info-image-input-container">
                <p id="admin-settings-catalog-banners-new-info-image-input-label">
                    Imagem
                </p>
                <div id="admin-settings-catalog-banners-new-info-image-input" onClick={handleTriggerUpload}>
                    <input 
                        ref={imageInputBtnRef}
                        style={{display: "none"}} 
                        type="file" 
                        onChange={(e) => {handleImageUpload(e.target.files)}}
                    />
                    
                    <img 
                        id="admin-settings-catalog-banners-new-info-image"
                        src={bannerConfigurationContext?.selectedBanner.content === ""? PlaceholderImage: bannerConfigurationContext?.selectedBanner.content} 
                        alt="" 
                        draggable={false}
                    />
                </div>
            </div>
        </div>
    )
}