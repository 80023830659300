import React, { useState, useEffect, useContext } from "react"

import "./recover.css"

import { Link, useNavigate, useParams } from "react-router-dom"
import { AuthContext }       from "context/authProvider"
import { apiCaller }         from "api/apiCaller"
import TextField             from "components/inputs/textfieldUnderline/textfieldUnderline"
import PasswordField         from "components/inputs/passwordfieldUnderline/passwordfieldUnderline"
import Logo                  from "assets/logo/logoTransparentBackground2.png"
import { AxiosError, AxiosResponse } from "axios"


export default function Recover(){

    const params = useParams()

    const [screenHeight, setScreenHeight] = useState<number>(0)
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [error, setError] = useState<string>("")

    
    useEffect(() => {
        let height = window.screen.availHeight
        
        console.log(window.screen.availHeight)
        console.log(window.outerHeight)
        console.log(window.innerHeight)
        
        height = height - (window.outerHeight - window.innerHeight)
        setScreenHeight(height)
    },[window.innerHeight])



    function handlePasswordChange(value: string) {
        setPassword(value);
    }
    
    function handleConfirmPasswordChange(value: string) {
        setConfirmPassword(value);
    }

    function handleResetError(){
        setError("")
    }

    function handleRecover() {
        if (password === ""){
            setError("senha inválida")
        }
        else if (confirmPassword === ""){
            setError("confirmação de senha inválida")
        }
        else if (password !== confirmPassword){
            setError("a senha e a confirmação não batem")
        } else {
            apiCaller.post("/accounts/new_password", {
                newPassword: password
            },
            {
                params: {
                    code: params.url
                }
            }).then((response: AxiosResponse) => {
                console.log(response)
            }).catch((error: AxiosError) => {
                console.log("erro", error)
            });
        }

    }


    return (
        <div id="recover-page-main" style={{height: screenHeight}}>
            <div id="recover-page-container">
                <div id="recover-page-logo-container" >
                    <Link id="recover-page-logo-link" to={"/"} draggable={false}>
                        <img id="recover-page-logo" src={Logo} alt="" draggable={false}/>
                    </Link>
                </div>
                <div id="recover-page-content">
                    <div className="login-input-container">
                        <p id="recover-page-text">
                            Insia sua nova senha
                        </p>
                    </div>
                    <div className="recover-page-input-container">
                        <PasswordField
                            label="Senha"
                            onValueChange={handleConfirmPasswordChange}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="recover-page-input-container">
                        <PasswordField
                            label="Confirmação de Senha"
                            onValueChange={handlePasswordChange}
                            onSelected={handleResetError}
                        />
                    </div>  
                    <div id="recover-page-warning">
                        {
                            error &&
                            (
                                <p id="recover-page-warning-txt">
                                    {error}
                                </p>
                            )
                        }
                    </div>                   
                    <div id="recover-page-input-container-btn">
                        <div id="recover-page-input-container-btn-confirm" onClick={handleRecover}>
                            <p id="recover-page-input-container-btn-confirm-txt">
                                Confirmar
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}