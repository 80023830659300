import React, { useEffect, useState, useContext } from "react"
import "./cart.css"

import { Link } from "react-router-dom"
import { CartStorageContext } from "context/cartStorageProvider"
import CartIcon from "assets/icons/bag.png"

export default function Cart(){

    const cartStorageContext = useContext(CartStorageContext)
    
    return (
        <Link id="header-cart-main" to={"/carrinho"} draggable={false}>
            <div id="header-cart-img-container">
                <img 
                    id="header-cart-img"
                    src={CartIcon}
                    alt="" 
                    draggable={false}
                />
            </div>
                {
                    cartStorageContext &&
                    (
                        <p id="header-cart-produc-quantity-txt">
                            {cartStorageContext.productQuantity <= 99? cartStorageContext.productQuantity: "99+"}
                        </p>
                    )
                }
        </Link>
    )
}