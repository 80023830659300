import React, {useState, useEffect} from "react"

import "./textInput.css"

export default function TextInput(props: {value?: string | number, placeholder?: string, disabled?: boolean, onValueChange?: Function}){

    const [value, setValue] = useState<string | number>("")

    useEffect(() => {
        if (typeof props.value === "string"){
            setValue(props.value || "")
        }
        if (typeof props.value === "number"){
            setValue(props.value || 0)
        }
    },[props.value])

    function handleValueChange(value: string | number){
        setValue(value)
        if (props.onValueChange){
            props.onValueChange(value)
        }
    }

    return (
        <div id="product-new-size-list-size-element-textinput-main">
            <input 
                id="product-new-size-list-size-element-textinput-field" 
                onChange={(e) => {handleValueChange(e.target.value)}}
                disabled={props.disabled} 
                value={value}
                placeholder={props.placeholder}
            />
        </div>
    )
}