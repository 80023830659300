import React, { useState, useEffect, useContext, useRef } from "react"

import "./payment.css"

import { CartContext } from "../../userCart"
import { apiCaller } from "api/apiCaller"
import MercadoPagoWallet from "components/mercadopago/mercadoPagoWallet"
import LoadingAnimation from "components/animations/loadingAnimation";
import { AuthContext } from "context/authProvider"
import { AxiosError, AxiosResponse } from "axios"

export default function Payment(){

    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)

    const [preferenceId, setPreferenceId] = useState<string>("")
    const [error, setError] = useState<string>("")

    useEffect(() => {
        console.log(cartContext?.order)

        apiCaller.post("/checkout/", {
            addressId: cartContext?.order.addressId,
            products: cartContext?.order.products,
        },
        {
            headers: {
                "Authorization": `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            setPreferenceId(response.data.data)
        }).catch((error: AxiosError) => {
            console.log(error)
        })

    },[])

    return (
        <div id="cart-payment-main">
            <div id="card-payment-method-selector-label">
                <p id="card-payment-method-selector-label-txt">
                    Estamos quase lá, prossiga para o Mercado Pago para finalizar a compra
                </p>
            </div>
            <div id="cart-payment-container">
                {
                    preferenceId &&
                    (
                        <MercadoPagoWallet
                            preferenceId={preferenceId}
                        />
                    )
                }
                {
                    !preferenceId &&
                    (
                        <div id="cart-payment-loading">
                            <LoadingAnimation
                                style={{width: "200px", height: "200px", position: "relative"}}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}