import React, {useState, useEffect, useContext} from "react"
import { Link } from "react-router-dom"

import "./header.css"

import SearchField from "components/searchField/searchField"
import Dropdown from "components/inputs/dropdownWithoutLabel/dropdownWithoutLabel"
import { GlobalContext } from "context/globalProvider"

export default function Header(props: {onSearch: Function}){

    const globalContext = useContext(GlobalContext)

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [searchCategory, setSearchCategory] = useState<string>("")
    const [categories, setCategories] = useState<string[]>([])
    const [orderTypes, setOrderTypes] = useState<string[]>([])

    useEffect(() => {

        const categories = globalContext?.categories.map((category) => {
            return category.name
        })

        categories?.push("Todos")
        if (categories){
            setCategories(categories)
        }

        setOrderTypes([
            "preço",
            "estoque",
            "nenhum"
        ])

        setSearchCategory("Todos")
    },[globalContext?.categories])

    function handleSearch(value: string){
        props.onSearch(value, searchCategory)
        setSearchTerm(value)
    }

    function handleCategorySelect(parameter: string){
        props.onSearch(searchTerm, parameter)
        setSearchCategory(parameter)
    }

    function handleOrder(){

    }

    function handleChangeOrder(){

    }

    return (
        <div id="admin-product-list-header-main">
            <div id="admin-product-list-header-searchfield">
                <SearchField
                    placeholder="Insira o nome do produto"
                />
            </div>
            <div id="admin-product-list-header-category-filter">
                <Dropdown
                    onSelect={handleCategorySelect}
                    options={categories}
                    defaultOption={"Todos"}
                />
            </div>
            <div id="admin-product-list-header-new-product">
                <Link id="admin-product-list-header-new-product-btn" to={"/admin/produtos/novo"}>
                    Novo Produto
                </Link>
            </div>
        </div>
    )
}