import React, { useState, useEffect, useContext } from "react"

import Lottie        from "lottie-react"
import loadAnimation from "assets/animations/payment-fail.json"

export default function PaymentFailAnimation(props: {style: React.CSSProperties}){

    return (
        <Lottie
            animationData={loadAnimation}
            autoplay={true}
            loop={false}
            style={props.style}
        />
    )
}