import React, {useState, useEffect, useContext} from "react"
import { Link } from "react-router-dom"

import "./header.css"

import { ProductContext } from "pages/admin/product/product/product" 

export default function Header(props: {onSave: Function}){
    const productContext = useContext(ProductContext)

    const [isMounted, setIsMounted] = useState<boolean>(false)

    useEffect(() => {
        if (isMounted){
            const tempoEmMilissegundos = 4.5 * 1000;
            const timeoutId = setTimeout(clearError, tempoEmMilissegundos);
            return () => {
                clearTimeout(timeoutId)
            }
        } else {
            setIsMounted(true)
        }
    }, [productContext?.error])

    useEffect(() => {
        if (productContext?.product.name){
            setName(productContext?.product.name)
        }
    },[productContext?.product])

    function clearError(){
        if (productContext){
            productContext.setError("")
        }
    }

    const [name, setName] = useState<string>("")

    function handleNameChange(value: string){
        setName(value)
    
        if (productContext) {
            productContext.setProduct((prev) => ({
                ...prev,
                name: value
            }))
        }
    }


    function handleSave(){
        props.onSave()
    }

    return (
        <div 
            id="admin-product-header-main" 
        >
            <div id="admin-product-header-name">
                <input 
                    id="admin-product-header-name-input"
                    type="text" 
                    placeholder="Nome"
                    onChange={(e) => {handleNameChange(e.target.value)}} 
                    value={name}
                />
            </div>
            <div id="admin-product-header-error">
                {   
                    productContext?.error &&
                    (
                        <p id="admin-product-header-error-txt">
                            {productContext.error}
                        </p>
                    )
                }
            </div>
            <div id="admin-product-header-btns">
                <p 
                    id="admin-product-header-btns-save" 
                    className="admin-product-header-btn"
                    onClick={handleSave}
                >
                    Salvar
                </p>
                <Link 
                    id="admin-product-header-btns-delete" 
                    className="admin-product-header-btn"
                    to={"/admin/produtos"}
                >
                    Cancelar
                </Link>
            </div>
        </div>
    )
}