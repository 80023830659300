import React, {useState, useEffect} from "react"

import "./fullscreenLoading.css"

import Lottie from "lottie-react"

import Animation from "assets/animations/spiner-load.json"

export default function FullscreenLoading(){

    const [screenHeight, setScreenHeight] = useState<number>(0)
    
    useEffect(() => {
        let height = window.screen.availHeight
        height = height - (window.outerHeight - window.innerHeight)

        setScreenHeight(height)
    },[window.innerHeight])

    function handleAnimationStart(){
        window.scrollTo(0, 0);
        document.documentElement.style.overflow = "hidden";
    }

    return (
        <div id="fullscreen-loading-main" style={{height: (screenHeight? screenHeight: window.innerHeight)}}>
            <Lottie
                animationData={Animation}
                autoPlay={true}
                loop={true}
                onDOMLoaded={handleAnimationStart}
                style={{width: "250px", height: "250px", position: "absolute"}}
            />
        </div>
    )
}