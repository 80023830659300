import React, {useState, useEffect} from "react"

import "./details.css"



import OrderProductCard from "pages/admin/orders/components/orderProductCard/orderProductCard"
import TimeDateParser   from "misc/timeDateParser"
import IOrder           from "interfaces/order/IOrder"
import { IPaymentCreditCard, IPaymentPix, IPayment } from "interfaces/order/IPayment"

export default function Details(props: {data: IOrder, onCancelOrder: Function}){

    function handleArchive(){

    }

    function handleCancelOrder(){
        props.onCancelOrder(props.data.id)
    }

    function parsePaymentMethod(payment: IPayment): string {
        
        if ("company" in payment.data) {

            return `cartão de credito ${payment.data.company} ${payment.data.instalmentQuantity}x R$ ${payment.data.instalentValue}`

        } else {
            return `pix 1x R$ ${payment.data.total}`
        }
    }   

    function parseTotal(payment: IPayment): string {
        return `R$ ${payment.data.total}`
    }   

    return (
        <div id="admin-shipped-orders-order-detail-main">
            <div id="admin-shipped-orders-order-detail-order-header">
                <div id="admin-shipped-orders-order-detail-order-header-identifier">
                    <p id="admin-shipped-orders-order-detail-order-header-identifier-text1">
                        Pedido
                    </p>
                    <p id="admin-shipped-orders-order-detail-order-header-identifier-text2">
                        {props.data.id}
                    </p>
                </div>
                <div id="admin-shipped-orders-order-detail-order-header-cancel">
                    <p id="admin-shipped-orders-order-detail-order-header-cancel-btn2">
                        Cancelar pedido
                    </p>
                </div>
            </div>
            <div id="admin-shipped-orders-order-detail-body">
                <div id="admin-shipped-orders-order-detail-body-data">
                    <div id="admin-shipped-orders-order-detail-body-data-payment">
                        <div id="admin-shipped-orders-order-detail-body-data-payment-title">
                            <p id="admin-shipped-orders-order-detail-body-data-payment-title-text">
                                Dados do pedido
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">Nome:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.client?.name}
                            </p>
                            <p className="text-label">CPF:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.client?.cpf}
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">E-mail:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.client?.email}
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">Pagamento:</p>
                            <div id="admin-shipped-orders-order-detail-body-data-payment-method-detail1">
                                {
                                    props.data.payment &&
                                    (   
                                        <p id="admin-shipped-orders-order-detail-body-data-payment-method-detail1-text">
                                            {parsePaymentMethod(props.data.payment)}
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">Valor total do pedido:</p>
                            {
                                props.data.payment &&
                                (
                                    <p className="admin-shipped-orders-order-info-text">
                                        {parseTotal(props.data.payment)}
                                    </p>
                                )
                            }
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">Data do pedido:</p>
                            {
                                props.data.timeStamp &&
                                (
                                    <p className="admin-shipped-orders-order-info-text">
                                        {
                                            TimeDateParser.parseDate(
                                                props.data.timeStamp?.date.day,
                                                props.data.timeStamp?.date.month,
                                                props.data.timeStamp?.date.year
                                            )
                                        }
                                    </p>
                                )
                            }
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">Horário:</p>
                            {
                                props.data.timeStamp &&
                                (
                                    <p className="admin-shipped-orders-order-info-text">
                                        {
                                            TimeDateParser.parseTime(
                                                props.data.timeStamp.time.hour,
                                                props.data.timeStamp.time.minute
                                            )
                                        }
                                    </p>
                                )
                            }
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-payment-client-fields">
                            <p className="text-label">Valor do frete:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                R$ {`${props.data.shipment?.price}`.replace(".", ",")}
                            </p>
                        </div>
                    </div>
                    <div id="admin-shipped-orders-order-detail-body-data-shipment">
                        <div id="admin-shipped-orders-order-detail-body-data-shipment-title">
                            <p id="admin-shipped-orders-order-detail-body-data-shipment-title-text">
                                Dados da entrega
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-shipment-client-fields">
                            <p className="text-label">Logradouro:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.address.address1}
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-shipment-client-fields">
                            <p className="text-label">
                                Numero:
                            </p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.address.unitNumber}
                            </p>
                            {
                                props.data.shipment?.address.complement &&
                                (
                                    <>
                                        <p className="text-label">
                                            Complemento:
                                        </p>
                                        <p className="admin-shipped-orders-order-info-text">
                                            {props.data.shipment?.address.complement}
                                        </p>
                                    </>
                                )
                            }
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-shipment-client-fields">
                            <p className="text-label">Cidade:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.address.city}
                            </p>
                            <p className="text-label">Bairro:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.address.address2}
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-shipment-client-fields">
                            <p className="text-label">Estado:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.address.state}
                            </p>
                        </div>
                        <div className="admin-shipped-orders-order-detail-body-data-shipment-client-fields">
                            <p className="text-label">CEP:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.address.postalCode}
                            </p>
                            <p className="text-label">Código de restreio:</p>
                            <p className="admin-shipped-orders-order-info-text">
                                {props.data.shipment?.trackCode}
                            </p>
                        </div>
                    </div>
                    <div id="admin-shipped-orders-order-detail-body-data-trial">
                        <div 
                            id="admin-shipped-orders-order-detail-body-data-trial-btn" 
                            onClick={handleArchive}
                        >
                            <p id="admin-shipped-orders-order-detail-body-data-trial-btn-text">
                                Arquivar
                            </p>
                        </div>
                    </div>
                </div>
                <ul id="admin-shipped-orders-order-detail-body-products">
                    {
                        props.data.products &&
                        (
                            props.data.products.map(product => {
                                return (
                                    <OrderProductCard
                                        key={product.data.id}
                                        product={product}
                                    />
                                )
                            })
                        )
                    }
                </ul>
            </div>
        </div>
    )
}