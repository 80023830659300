import React, {useState, useEffect, useContext} from "react"

import "./productList.css"

import Header from "./components/header/header"
import Product from "./components/product/product"
import { apiCaller } from "api/apiCaller"
import { AuthContext } from "context/authProvider"
import AdminPageTemplate from "pages/admin/components/adminPageTemplate/adminPageTemplate"
import FullscreenLoading from "components/fullscreenLoading/fullscreenLoading"
import ActionConfirmation from "components/actionConfirmation/actionConfirmation"

import { IProduct } from "interfaces/product/IProduct"
import { AxiosError, AxiosResponse } from "axios"
import { GlobalContext } from "context/globalProvider"

export default function AdminProductList(){

    const globalContext = useContext(GlobalContext)
    const authContext = useContext(AuthContext)

    const [renderFilteredProducts, setRenderFilteredProducts] = useState<boolean>(false)
    const [products, setProducts] = useState<IProduct[]>([])
    const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [actionConfirmation, setActionConfirmation] = useState<boolean>(true)

    useEffect(() => {

        let triggerAnimation: boolean = true

        apiCaller.get('/products/', {
            headers: {
                "Authorization": `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            setProducts(response.data.data)
            console.log(response.data.data)
        }).catch((error: AxiosError) => {

        }).finally(() => {
            if (triggerAnimation){
                triggerAnimation = false
                setIsLoading(false)
            }
        })
    },[])

    function filter(searchValue: string, category: string){
        if (category !== "Todos"){
            const searchedCategory = globalContext?.categories.find((cat) => {
                console.log(cat.name)
                if (cat.name === category){
                    return cat
                }
            })

            const filteredByCategory = products.filter((product) => {
                if (product.categoryId === searchedCategory?.id){
                    return product
                }
            })

            console.log(searchedCategory)
            console.log(filteredByCategory)

            if (searchValue){
                const filteredByText = filteredByCategory.filter((product) => {
                    if (product.name.toLowerCase().startsWith(searchValue.toLowerCase())){
                        return product
                    }
                })
                setFilteredProducts(filteredByText)
                setRenderFilteredProducts(true)
            } else {
                setFilteredProducts(filteredByCategory)
                setRenderFilteredProducts(true)
            }
        }
     
        // if (category !== "todos"){
        //     console.log(searchValue, category)
        //     const catt = globalContext?.categories.find((cat) => {
        //         if(cat.name === category){
        //             return cat.id
        //         }
        //     })

        //     console.log("catt", catt)

        //     if (catt === undefined){
        //         setFilteredProducts([])
        //         setRenderFilteredProducts(true)
        //         return
        //     }

        //     const filteredByCategory = products.filter((product) => {
        //         if (product.categoryId.includes(catt?.name)){
        //             return product
        //         }
        //     })
            
        //     if (searchValue !== ""){
        //         const filteredByText = filteredByCategory.filter((product) => {
        //             if (product.name.toLowerCase().startsWith(searchValue.toLowerCase())){
        //                 return product
        //             }
        //         })
        //         setFilteredProducts(filteredByText)
        //         setRenderFilteredProducts(true)
        //     } else {
        //         setFilteredProducts(filteredByCategory)
        //         setRenderFilteredProducts(true)
        //     }
        // } else {
        //     if (searchValue !== ""){
        //         const filteredByText = products.filter((product) => {
        //             if (product.name.toLowerCase().startsWith(searchValue.toLowerCase())){
        //                 return product
        //             }
        //         })
        //         setFilteredProducts(filteredByText)
        //         setRenderFilteredProducts(true)
        //     } else {
        //         setRenderFilteredProducts(false)
        //     }
        // }
    }

    return (
        <>  
            {
                isLoading &&
                (
                    <FullscreenLoading/>
                )
            }
            {
                // actionConfirmation &&
                // (
                //     <ActionConfirmation
                //         message="Você tem certeza que deseja arquivar o produto?"
                //         onConfirm={() => {}}
                //         onCancel={() => {}}
                //     />
                // )
            }
            <AdminPageTemplate navigationUrl="/admin/produtos">
                <div id="admin-product-list-main">
                    <Header 
                        onSearch={filter}
                    />
                    <div id="admin-product-list-body">
                        {
                            !renderFilteredProducts && products.map((product) => {
                                return (
                                    <Product 
                                        key={product.id}
                                        data={product}
                                    />
                                )   
                            })
                        }
                        {
                            filteredProducts.map((product) => {
                                return (
                                    <Product 
                                        key={product.id}
                                        data={product}
                                    />
                                )   
                            })
                        }
                    </div>
                </div>
            </AdminPageTemplate>
        </>
    )
}