import React, {useContext, useEffect} from "react"

import "./userProfileCartCard.css"

import PriceParser            from "misc/priceParser"
import { CartStorageContext } from "context/cartStorageProvider"
import DeleteIcon             from "assets/icons/delete-black.png"
import { IProduct }           from "interfaces/product/IProduct"
import { getProductImageUrl } from "misc/imageUrls"

export default function UserProfileCartCard(
    props: {
        product: {
            quantity: number, 
            data: IProduct
        }, 
        onDelete: Function
    }
){

    const cartStorageContext = useContext(CartStorageContext)

    function handleDelete(){
        props.onDelete(props.product.data.id, props.product.data.variations[0].id, props.product.data.variations[0].sizes)
    }

    function handleIncreaseQuantity(){
        if (cartStorageContext){
            cartStorageContext.increaseQuantity(
                props.product.data.id,
                props.product.data.variations[0].id, 
                props.product.data.variations[0].sizes[0].size
            )
        }
    }

    function handleDecreaseQuantity(){
        if (cartStorageContext){
            cartStorageContext.decreaseQuantity(
                props.product.data.id,
                props.product.data.variations[0].id, 
                props.product.data.variations[0].sizes[0].size
            )
        }
    }

    return (
        <li id="user-profile-card-card-main">
            <div id="user-profile-card-img-container">
                <img 
                    id="user-profile-card-img"
                    src={getProductImageUrl(props.product.data.variations[0].images[0].filename)} 
                    alt="" 
                    draggable={false}
                />
            </div>
            <div id="user-profile-card-info">
                <div id="user-profile-card-info-name">
                    <p id="user-profile-card-info-name-txt">
                        {props.product.data.name}
                    </p>
                </div>
                <div id="user-profile-card-info-general">
                    <div id="user-profile-card-info-general1">
                        <div className="user-profile-card-info-general-field">
                            <p className="user-profile-card-info-general-field-label">
                                Variação
                            </p>
                            <p className="user-profile-card-info-general-field-value">
                                {props.product.data.variations[0].name}
                            </p>
                        </div>
                        <div className="user-profile-card-info-general-field">
                            <p className="user-profile-card-info-general-field-label">
                                Tamanho
                            </p>
                            <p className="user-profile-card-info-general-field-value">
                                {props.product.data.variations[0].sizes[0].size}
                            </p>
                        </div>
                    </div>
                    <div id="user-profile-card-info-general2">
                        <div className="user-profile-card-info-general-field">
                            <p className="user-profile-card-info-general-field-label">
                                Quantidade
                            </p>
                            <div id="user-profile-card-info-general-field-size">
                                <p 
                                    id="user-profile-card-info-general-field-size-change-remove"
                                    onClick={handleDecreaseQuantity}
                                >
                                    -
                                </p>
                                <p className="user-profile-card-info-general-field-value">
                                    {props.product.quantity}
                                </p>
                                <p 
                                    id="user-profile-card-info-general-field-size-change-add"
                                    onClick={handleIncreaseQuantity}
                                >
                                    +
                                </p>
                            </div>
                        </div>
                        <div className="user-profile-card-info-general-field">
                            <p className="user-profile-card-info-general-field-label">
                                Total
                            </p>
                            {
                                props.product.data.variations[0].promotionEnabled &&
                                (
                                    <p className="user-profile-card-info-general-field-value">
                                        R$ {
                                            PriceParser.parse(
                                                props.product.data.variations[0].promotionPrice * props.product.quantity
                                            )
                                        }
                                    </p>
                                )
                            }
                            {
                                !props.product.data.variations[0].promotionEnabled &&
                                (
                                    <p className="user-profile-card-info-general-field-value">
                                        R$ {
                                            PriceParser.parse(
                                                props.product.data.variations[0].price * props.product.quantity
                                            )
                                        }
                                    </p>
                                )
                            }
                        </div>
                    </div>
                    {/* <div id="user-profile-card-info-general3">
                        <img 
                            id="user-profile-card-info-general3-delete-btn"
                            src={DeleteIcon}
                            alt="" 
                        />
                    </div> */}
                </div>
                {/* <div id="user-profile-card-info-btn">
                    <p id="user-profile-card-info-btn-delete" onClick={handleDelete}>
                        Excluir
                    </p>
                </div> */}
                {/* <div id="user-prpfile-card-info1">
                    <div id="user-profile-card-info1-name-field">
                        <p id="user-profile-card-info1-name-txt">
                            {props.product.data.name}
                        </p>
                    </div>
                    <div className="user-profile-card-info1-field">
                        <p className="user-profile-card-info1-field-label-txt">
                            Tamanho  
                        </p>
                        <p className="user-profile-card-info1-field-txt">
                            {props.product.data.variation.size}
                        </p>
                    </div>
                    <div className="user-profile-card-info1-field">
                        <p className="user-profile-card-info1-field-label-txt">
                            Variação  
                        </p>
                        <p className="user-profile-card-info1-field-txt">
                            {props.product.data.variation.color}
                        </p>
                    </div>
                    <div id="user-profile-card-info1-btn-field">
                        <p id="user-profile-card-info1-btn-delete" onClick={handleDelete}>
                            Excluir
                        </p>
                    </div>
                </div>
                <div id="user-profile-card-info2">
                    <p id="user-profile-card-info2-price-label-txt">
                        Preço
                    </p>
                    <p id="user-profile-card-info2-price-txt">
                        R$ {`${props.product.data.variation.price}`.replace(".", ",")}
                    </p>
                </div> */}
            </div>
        </li>
    )



    // return (
    //     <li id="user-profile-card-card-main">
    //         <div id="user-profile-card-img-container">
    //             <img 
    //                 id="user-profile-card-img"
    //                 src={Roupa} 
    //                 alt="" 
    //                 draggable={false}
    //             />
    //         </div>
    //         <div id="user-profile-card-info">
    //             <div id="user-prpfile-card-info1">
    //                 <div id="user-profile-card-info1-name-field">
    //                     <p id="user-profile-card-info1-name-txt">
    //                         {props.product.data.name}
    //                     </p>
    //                 </div>
    //                 <div className="user-profile-card-info1-field">
    //                     <p className="user-profile-card-info1-field-label-txt">
    //                         Tamanho  
    //                     </p>
    //                     <p className="user-profile-card-info1-field-txt">
    //                         {props.product.data.variation.size}
    //                     </p>
    //                 </div>
    //                 <div className="user-profile-card-info1-field">
    //                     <p className="user-profile-card-info1-field-label-txt">
    //                         Variação  
    //                     </p>
    //                     <p className="user-profile-card-info1-field-txt">
    //                         {props.product.data.variation.color}
    //                     </p>
    //                 </div>
    //                 <div id="user-profile-card-info1-btn-field">
    //                     <p id="user-profile-card-info1-btn-delete" onClick={handleDelete}>
    //                         Excluir
    //                     </p>
    //                 </div>
    //             </div>
    //             <div id="user-profile-card-info2">
    //                 <p id="user-profile-card-info2-price-label-txt">
    //                     Preço
    //                 </p>
    //                 <p id="user-profile-card-info2-price-txt">
    //                     R$ {`${props.product.data.variation.price}`.replace(".", ",")}
    //                 </p>
    //             </div>
    //         </div>
    //     </li>
    // )
}