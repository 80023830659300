import React, {useContext, useEffect, useState} from "react"

import "./personalDataEdit.css"

import { ProfileDataContext } from "../../userPersonalData"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import {Validate} from "misc/validate"

interface IPersonalDataEditError {
    field1: string
    field2: string
    field3: string
}

export default function PersonalDataEdit(){

    const profileDataContext = useContext(ProfileDataContext)

    const [fieldName,     setFieldName    ] = useState<string>("")
    const [fieldValue,    setFieldValue   ] = useState<string>("")
    const [auxFieldValue, setAuxFieldValue] = useState<string>("")
    const [error,         setError        ] = useState<string>("")

    useEffect(() => {
        setFieldName(profileDataContext?.fieldToEdit || "")
    },[])

    function handleCancelEditField(){
        profileDataContext?.setFieldToEdit("")
        profileDataContext?.navigate("data")
    }

    function handleSaveEditField(){

    }

    function handleValueChange(value: string){
        setFieldValue(value)
    }

    function handleAuxValueChange(value: string){
        setAuxFieldValue(value)
    }

    function handleResetError(){
        setError("")
    }

    return (
        <div id="user-profile-personal-data-edit-content">
            <div id="user-profile-personal-data-edit-label">
                {   
                    fieldName &&
                    (
                        <p id="user-profile-personal-data-edit-label-txt">
                            {fieldName === "senha"? "Insira os dados para alterar a senha": `insira os dados para alterar o ${fieldName.toLowerCase()}`}
                        </p>

                    )
                }
            </div>
            <div id="user-profile-personal-data-edit-field">
                <div id="user-profile-personal-data-edit-text">
                    <TextFieldUnderline
                        label={fieldName}
                        onValueChange={handleValueChange}
                        onSelected={handleResetError}
                        value={fieldValue}
                    />
                </div>
                {   
                    fieldName === "senha" &&
                    (
                        <>
                            <div className="user-profile-personal-data-edit-text2">
                                <TextFieldUnderline
                                    label="Nova senha"
                                    onValueChange={handleValueChange}
                                    onSelected={handleResetError}
                                    value={fieldValue}
                                />
                            </div>
                            <div className="user-profile-personal-data-edit-text2">
                                <TextFieldUnderline
                                    label="Repita a nova senha"
                                    onValueChange={handleAuxValueChange}
                                    onSelected={handleResetError}
                                    value={auxFieldValue}
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <div id="user-profile-personal-data-warning">
                {
                    error &&
                    (
                        <p id="user-profile-personal-data-warning-txt">

                        </p>
                    )
                }
            </div>
            <div id="user-profile-personal-data-edit-btn">
                <p 
                    id="user-profile-personal-data-edit-btn-save"
                    onClick={handleSaveEditField}
                >
                    Salvar
                </p>
                <p 
                    id="user-profile-personal-data-edit-btn-cancel"
                    onClick={handleCancelEditField}    
                >
                    Cancelar
                </p>
            </div>
        </div>
    )
}