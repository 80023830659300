import React, {useState, useEffect, useContext} from "react"
import { useNavigate } from "react-router-dom"

import "./userMenu.css"

import { AuthContext } from "context/authProvider"
import Header                from "components/header/header"
import Footer                from "components/footer/footer"
import UserProfileMenuEntry  from "./components/userProfileMenuEntry/userProfileMenuEntry"
import Cart                  from "assets/icons/pages/userMenu/cart.png"
import PersonalData          from "assets/icons/pages/userMenu/personal-data.png"
import Orders                from "assets/icons/pages/userMenu/orders.png"
import Address               from "assets/icons/pages/userMenu/address.png"

export default function UserMenu(){

    const navigate = useNavigate()
    const authContext = useContext(AuthContext)

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        
        if (headerElement){
            const headerHeight = headerElement.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])

    function handleLogout(){
        authContext?.rmToken()
        navigate("/")
    }

    return (
        <>
            <Header/>
            <div id="user-profile-menu-main" style={{height: screenHeight}}>
                <div id="user-profile-menu-content-link-container">
                    <div id="user-profile-menu-content-link-label">
                        <p id="user-profile-menu-content-link-label-txt">
                            Sua conta
                        </p>
                        <p id="user-profile-menu-content-link-logout-txt" onClick={handleLogout}>
                            Sair
                        </p>
                    </div>
                    <ul id="user-profile-menu-content-links">
                        <UserProfileMenuEntry 
                            data={{
                                description: "Acesse seu perfil com suas preferências e dados pessoais", 
                                label: "Dados Pessoais", 
                                url: "/perfil/dados",
                                image: PersonalData
                            }}
                        />
                        <UserProfileMenuEntry 
                            data={{
                                description: "Acompanhe seus pedidos em todas as etapas", 
                                label: "Pedidos", 
                                url: "/perfil/pedidos",
                                image: Orders
                            }}
                        />
                        <UserProfileMenuEntry 
                            data={{
                                description: "Gerencie os endereços de envio", 
                                label: "Endereços", 
                                url: "/perfil/enderecos",
                                image: Address
                            }}
                        />
                        <UserProfileMenuEntry 
                            data={{
                                description: "Adicione produtos e finalize sua compra", 
                                label: "Carrinho", 
                                url: "/carrinho",
                                image: Cart
                            }}
                        />
                    </ul>
                </div>
            </div>
            <Footer/>
        </>

    )
}