import React, {useContext} from "react"

import "./product.css"

import VariationSelector from "./components/variationSelector/variationSelector"
import { useNavigate } from "react-router-dom"
import DeleteIcon from "assets/icons/delete.png"
import EditIcon from "assets/icons/edit2.png"
import { apiCaller } from "api/apiCaller"
import { AuthContext } from "context/authProvider"

import { IProduct } from "interfaces/product/IProduct"
import { AxiosError, AxiosResponse } from "axios"

export default function Product(props: {data: IProduct}){

    const authContext = useContext(AuthContext)

    const navigate = useNavigate()

    function handleEditProduct(){
        navigate("/admin/produtos/novo", {state: {id: props.data.id}})
    }
    
    return (
        <li id="admin-product-list-product-main">
            <div id="admin-product-list-product-name">
                <p id="admin-product-list-product-name-txt">
                    {props.data.name}
                </p>
            </div>
            <div id="admin-product-list-product-variations">
                <VariationSelector
                    data={props.data.variations}
                />
            </div>
            <div id="admin-product-list-product-categories">
                <p className="admin-product-list-product-categories-txt">
                    {/* {props.data.categoryId} */}
                </p>
                <p className="admin-product-list-product-categories-txt">
                    {/* {props.data.subcategory} */}
                </p>
            </div>
            <div id="admin-product-list-product-actions">
                <div id="admin-product-list-product-actions-edit">
                    <img 
                        className="admin-product-list-product-actions-img"
                        src={EditIcon}
                        alt="" 
                        onClick={handleEditProduct}
                        draggable={false}
                    />
                </div>
            </div>
        </li>
    )
}
