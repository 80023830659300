import axios, { AxiosInstance } from 'axios';

const apiCaller: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
});

apiCaller.defaults.maxRedirects = 10;

apiCaller.interceptors.response.use(
    response => {
        return response;
    }, 
    error => {
        if (error.response && [301, 302, 400, 404].includes(error.response.status)) {
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

export { apiCaller };