import React, { useState, useEffect, useContext } from "react"

import "./createAddress.css"

import TextField from "components/inputs/textfieldUnderline/textfieldUnderline"
import IShipmentAddress   from "interfaces/IShipmentAddress"
import { CartContext }    from "pages/user/cart/userCart"
import Dropdown           from "components/inputs/dropdownUnderline/dropdownUnderline"
import apiIBGEStates      from "api/apiIBGEStates"
import apiIBGECity        from "api/apiIBGECity"
import IState             from "interfaces/address/IState"
import ICity              from "interfaces/address/ICity"
import { apiCaller } from "api/apiCaller"
import { AuthContext } from "context/authProvider"
import { AxiosError, AxiosResponse } from "axios"


export default function CreateAddress(props: {onCreateAddressCancelClick?: Function}){

    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)

    const [stateFetchError, setStateFetchError] = useState<boolean>(false)
    const [stateData,       setStateData      ] = useState<IState[]>([])
    const [cityData,        setCityData       ] = useState<ICity[]>([])
    const [error,           setError          ] = useState<string>("")

    const [address, setAddress] = useState<IShipmentAddress>({
        id: "0",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: true
    })


    useEffect(() => {
        apiIBGEStates()
            .then(mappedData => {
                setStateData(mappedData);
            })
            .catch(error => {
                setStateFetchError(true)
                console.error("api error", error);
            });
    }, []);

    useEffect(() => {
        apiIBGECity(address.state)
        .then(mappedData => {
            setCityData(mappedData);
        })
        .catch(error => {
            console.error("api error", error);
        });
    },[address.state])

    function handleFieldChange(field: string, value: string | number) {

        if (field === "state"){
            setAddress((prev) => ({
                ...prev,
                city: ""
            }))
        }

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress

                if (keyTyped === field){
                    setAddress((previous) => ({
                        ...previous,
                        [keyTyped]: value
                    }))
                }
            }
        }
    }

    function handleSave(){
        console.log(address)
        let errorMessage: string = "campos inválidos: "
        let error = false

        if (!address.address1){
            error = true
            errorMessage = errorMessage.concat("rua, ")
        }
        if (!address.address2){
            error = true
            errorMessage = errorMessage.concat("bairro, ")
        }
        if (!address.city){
            error = true
            errorMessage = errorMessage.concat("cidade, ")
        }
        if (!address.name){
            error = true
            errorMessage = errorMessage.concat("nome, ")
        }
        if (address.postalCode.length < 8){
            error = true
            errorMessage = errorMessage.concat("CEP, ")
        } 
        if (!address.state){
            error = true
            errorMessage = errorMessage.concat("estado, ")
        }
        if (!address.unitNumber){
            error = true
            errorMessage = errorMessage.concat("número, ")
        }
        else {
            apiCaller
                .get(`/ext/cep?q=${address.postalCode}`)
                .then((response: AxiosResponse) => {
                    apiCaller.post('/addresses/', {
                        name: address.name,
                        address1: address.address1,
                        address2: address.address2,
                        unitNumber: address.unitNumber,
                        complement: address.complement,
                        city: address.city,
                        state: address.state,
                        postalCode: parseInt(address.postalCode),
                        default: address.default
                    }, {
                        headers: {
                            "Authorization": `Bearer ${authContext?.getToken()}`
                        }
                    }).then((response: AxiosResponse) => {
        
                        cartContext?.setOrder((prev) => ({
                            ...prev,
                            addressId: response.data.data.id
                        }))
        
                        cartContext?.setStatusTracker((prevStatus) => ({
                            ...prevStatus,
                            payment: true
                        }))
        
                        cartContext?.handleNavigation("payment")
        
                    }).catch((error: AxiosError) => {
                        console.log("erro")
                    });
                }).catch((error: AxiosError) => {
                    console.log(error);
                })
        }

        if (error){
            let lastComma = errorMessage.lastIndexOf(",")
            errorMessage = errorMessage.substring(0, lastComma)
            setError(errorMessage)
        }

    }

    function handleResetError(){
        setError("")
    }

    return (
        <div id="cart-address-create-address-main">
            <div id="cart-address-create-address-label">
                <p id="cart-address-create-address-label-txt">
                    Para proseguir com sua compra, nos informe um edereço
                </p>
                <div id="cart-address-create-address-warning">
                    {
                        error &&
                        (
                            <p id="card-address-create-address-warning-txt">
                                {error}
                            </p>
                        )
                    }

                </div>
            </div>
            <div id="cart-address-create-address-fields">
                <div className="cart-address-create-address-fields-container">
                    <div className="cart-address-create-address-fields">
                        <TextField
                            label="Nome"
                            onValueChange={(value: string) => {handleFieldChange("name", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="cart-address-create-address-fields">
                        <TextField
                            label="Endereço"
                            onValueChange={(value: string) => {handleFieldChange("address1", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="cart-address-create-address-fields">
                        <div className="cart-address-create-address-sub-fields">
                            <TextField
                                label="Número"
                                numeric={true}
                                onValueChange={(value: number) => {handleFieldChange("unitNumber", value)}}
                                onSelected={handleResetError}
                            />
                        </div>
                        <div className="cart-address-create-address-sub-fields">
                            <TextField
                                label="Complemento"
                                onValueChange={(value: string) => {handleFieldChange("complement", value)}}
                                onSelected={handleResetError}
                            />
                        </div>
                    </div>
                    <div className="cart-address-create-address-fields">
                        <TextField
                            label="Bairro"
                            onValueChange={(value: number) => {handleFieldChange("address2", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                </div>
                <div className="cart-address-create-address-fields-container">
                    <div className="cart-address-create-address-fields">
                        {
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Estado"
                                    showOnClick={true}
                                    options={stateData.map(state => {return state.abbreviation})}
                                    onSelect={(value: string) => {handleFieldChange("state", value); handleResetError()}}
                                    
                                />
                            )
                        }
                        {   
                            stateFetchError &&
                            (
                                <TextField
                                    label="Estado"
                                    onValueChange={(value: string) => {handleFieldChange("state", value)}}
                                    onSelected={handleResetError}
                                />
                            )
                        }
                    </div>
                    <div className="cart-address-create-address-fields">
                        {
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Cidade"
                                    showOnClick={true}
                                    options={cityData.map(city => {return city.name})}
                                    onSelect={(value: string) => {handleFieldChange("city", value); handleResetError()}}
                                    default={address.city}
                                />
                            )
                        }
                        {
                            stateFetchError &&
                            (
                                <TextField
                                    label="Cidade"
                                    onValueChange={(value: string) => {handleFieldChange("city", value)}}
                                    onSelected={handleResetError}
                                />
                            )
                        }
                    </div>
                    <div className="cart-address-create-address-fields">
                        <TextField
                            label="CEP"
                            maxCharacters={8}
                            numeric={true}
                            onValueChange={(value: string) => {handleFieldChange("postalCode", value)}}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div id="cart-address-create-address-btns-container">
                        <p id="cart-address-create-address-btns-continue" onClick={handleSave}>
                            Continuar
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}