export default class NumberValidade {
    static isNumericString(value: string): boolean {
        const num = parseFloat(value);
    

        console.log(value)
        console.log(!isNaN(num))
        console.log(num.toString() === value.trim())

        return !isNaN(num) && num.toString() === value.trim();
    }
}