import React from "react"

import "./statusTracker.css"

import UserProfileStatusTrackerItem from "./components/userProfileStatusTrackerItem"

import Box1        from "assets/icons/pages/cart/box.png"
import Box2        from "assets/icons/pages/cart/box2.png"
import PaymentIcon from "assets/icons/pages/cart/credit-card.png"
import CartIcon    from "assets/icons/pages/cart/cart.png"
import UserIcon    from "assets/icons/pages/cart/user.png"

import CartDisabledIcon    from "assets/icons/pages/cart/cart-disabled.png"
import UserDisabledIcon    from "assets/icons/pages/cart/user-disabled.png"
import AddressDisabledIcon from "assets/icons/pages/cart/box2-disabled.png"
import PaymentDisabledIcon from "assets/icons/pages/cart/credit-card-disabled.png"
import IStatusTracker      from "interfaces/cart/IStatusTracket"

export default function StatusTracker(props: {data: IStatusTracker}){
    return (
        <div id="user-profile-status-tracker-main">
            <UserProfileStatusTrackerItem 
                disabledImage={CartDisabledIcon} 
                name="Produtos"
                element="checkout"
                active={props.data.checkout}
                first={true}
            />
            <UserProfileStatusTrackerItem 
                disabledImage={UserDisabledIcon}
                name="Identificação"
                element="auth"
                active={props.data.auth}
            />
            <UserProfileStatusTrackerItem 
                disabledImage={AddressDisabledIcon}
                name="Envio"
                element="address"
                active={props.data.address}
            />
            <UserProfileStatusTrackerItem 
                disabledImage={PaymentDisabledIcon}
                name="Pagamento"
                element="payment"
                active={props.data.payment}
            />
            <UserProfileStatusTrackerItem 
                disabledImage={PaymentDisabledIcon}
                name="Confirmação"
                element="result"
                active={props.data.result}
            />
        </div>
    )
}