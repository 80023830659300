import React, {useRef, useState, useContext} from "react"

import "./bannerDetail.css"

import TextInput from "components/textInput/textInput"
import { BannerConfigurationContext } from "../../mainPage"
import PlaceholderImage from "assets/backgrounds/banner-placeholder.svg"

export default function BannerDetail(){

    const bannerConfigurationContext = useContext(BannerConfigurationContext)

    return (
        <div id="admin-settings-catalog-banners-detail">
            <div className="admin-settings-catalog-banners-detail-info-input-container">
                <TextInput
                    label="Nome"
                    onValueChange={() => {}}
                    disabled={true}
                    value={bannerConfigurationContext?.selectedBanner.filename}
                />
            </div>
            <div className="admin-settings-catalog-banners-detail-info-input-container">
                <TextInput
                    label="URL"
                    onValueChange={() => {}}
                    disabled={true}
                    value={bannerConfigurationContext?.selectedBanner.url}
                />
            </div>
            <div id="admin-settings-catalog-banners-detail-info-image-input-container">
                <p id="admin-settings-catalog-banners-detail-info-image-input-label">
                    Imagem
                </p>
                <div id="admin-settings-catalog-banners-detail-info-image-input">
                    <img 
                        id="admin-settings-catalog-banners-detail-info-image"
                        src={bannerConfigurationContext?.selectedBanner.content === ""? PlaceholderImage: bannerConfigurationContext?.selectedBanner.content} 
                        alt="" 
                        draggable={false}
                    />
                </div>
            </div>
        </div>
    )
}