import React, { useEffect, useState } from "react"

import "./userOrders.css"

import UserProfileTemplate  from "../components/userPageTemplate/userPageTemplate"
import UserProfileOrderCard from "./components/userProfileOrderCard/userProfileOrderCard"
import Order                from "assets/icons/pages/userOrders/screen-cart.png"
import IOrder               from "interfaces/order/IOrder"

import ROUPA from "assets/roupa.jpeg"

export default function UserOrders(){
    
    const [orders, setOrders] = useState<IOrder[]>([])

    useEffect(() => {
            
    },[])
    
    
    return (
        <UserProfileTemplate image={Order} name="Pedidos" navigationUrl="/perfil/pedidos">
            <div id="user-profile-orders-menu">
                {/* <Menu/> */}
            </div>
            <ul id="user-profile-orders-menu-list">
                {
                    orders.map((order) => {
                        return (
                            <UserProfileOrderCard
                                key={order.id}
                                data={order}
                            />
                        )
                    })
                }
            </ul>
        </UserProfileTemplate>
    )
}