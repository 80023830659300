import React, { useState, useEffect, useContext } from "react"

import "./personalData.css"

import { ProfileDataContext } from "../../userPersonalData"
import { GlobalContext } from "context/globalProvider"
import TextField from "components/inputs/textfieldUnderline/textfieldUnderline"

export default function PersonalData(){

    const globalContext = useContext(GlobalContext)
    const profileDataContext = useContext(ProfileDataContext)


    function handleEditField(field: string){
        profileDataContext?.setFieldToEdit(field)
        profileDataContext?.navigate("edit")
    }

    return (
        <div id="user-profile-personal-data-content">
            <div id="user-profile-personal-data-content-info1">
                <div className="user-profile-personal-data-content-textfield-container" id="user-profile-personal-data-content-textfield-container-first">
                    <TextField 
                        label="Nome"
                        // onEdit={() => {handleEditField("Nome")}}
                        onValueChange={() => {}}
                        value={globalContext?.user.name}
                        readonly={true}
                    />
                </div>
                <div className="user-profile-personal-data-content-textfield-container">
                    <TextField
                        label="Sobrenome"
                        // onEdit={() => {handleEditField("Sobrenome")}}
                        onValueChange={() => {}}
                        value={globalContext?.user.surname}
                        readonly={true}
                    />
                </div>
                <div className="user-profile-personal-data-content-textfield-container">
                    <TextField 
                        label="E-mail"
                        // onEdit={() => {handleEditField("Email")}}
                        onValueChange={() => {}}
                        value={globalContext?.user.email}
                        readonly={true}
                    />
                </div>
                <div className="user-profile-personal-data-content-textfield-container">
                    <TextField
                        label="CPF"
                        onValueChange={() => {}}
                        value={globalContext?.user.cpf}
                        readonly={true}
                    />
                </div>
            </div>
            <div id="user-profile-personal-data-content-info2">
                {/* <CheckBox
                    text="Deseja receber novidades de produtos e promoções"
                    onValueChange={() => {}}
                /> */}
            </div>
        </div>
    )
}