import React, {useEffect, useState} from "react"

import "./textFieldLarge.css"

import NumberValidade from "misc/numberValidade"

export default function TextFieldLarge(
    props: {
        label: string, 
        value?: string, 
        disabled?: boolean, 
        numeric?: boolean
        onValueChange: Function
    }
){

    const [value, setValue] = useState<string>("")

    useEffect(() => {
        if (props.value === "0"){
            setValue("")
        } else {
            setValue(props.value || "")
        }
    },[props.value])

    function handleValueChange(value: string){
        setValue(value)
        props.onValueChange(value)
    }

    return (
        <div id="text-field-large-main">
            <div id="text-field-large-text-label-container">
                <p id="text-field-large-text-label">
                    {props.label}
                </p>
            </div>
            <div id="text-field-large-text-field-container">
                <textarea 
                    spellCheck={false}
                    id="text-field-large-text-field" 
                    onChange={(e) => {handleValueChange(e.target.value)}}
                    disabled={props.disabled} 
                    value={value}
                />
            </div>
        </div>
    )
}