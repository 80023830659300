import React, {useCallback, useEffect, useState} from "react"

import "./textInput.css"

import NumberValidade from "misc/numberValidade"

export default function TextInput(
    props: {
        label: string, 
        value?: string, 
        disabled?: boolean, 
        monetary?: boolean,
        numeric?: boolean
        onValueChange: Function
    }
){

    const [value, setValue] = useState<string>("");
    const [stack, setStack] = useState<number[]>([]);

    useEffect(() => {
        if (props.value === "0"){
            setValue("")
        } else {
            setValue(props.value || "");
            if (props.monetary && props.value) {
                const numericValue = parseFloat(props.value);
                const stackValue = numericValue.toFixed(2).split('').map(Number).filter(value => !isNaN(value));
                setStack(stackValue);
            }
        }
    }, [props.value]);

    useEffect(() => {
        if (props.monetary) {
            const zeroAmount = 4 - stack.length;
            
            const str = zeroAmount > 0 ? "0".repeat(zeroAmount) + stack.join('') : stack.join('');
            const substring = str.slice(0, -2);
            const lastCharacters = str.slice(-2);
            
            const concat = substring + '.' + lastCharacters;
        
            setValue(parseFloat(concat).toLocaleString('pt-BR'));
            props.onValueChange(parseFloat(concat));
        }
    }, [stack, props.monetary]);

    function handleOnChange(value: string) {
        if (!props.monetary) {
            handleValueChange(value);
        }
    }

    function handleOnKeyPressed(value: string) {
        if (props.monetary) {
            handleMonetaryValues(value);
        }
    }

    function handleMonetaryValues(key: string) {
        if (key === 'Backspace') {
            setStack(stack.slice(0, -1));
        } else if (key.match('[0-9]')) {
            setStack([...stack, parseInt(key)]);
        }
    }

    function handleValueChange(value: string){
        if (props.numeric === true && props.monetary !== undefined) {
            if (NumberValidade.isNumericString(value)){
                setValue(value)
                props.onValueChange(value)
            }
        } else {
            setValue(value);
            props.onValueChange(value)
        }
    }

    return (
        <div id="text-input-main">
            <div id="text-input-text-label-container">
                <p id="text-input-text-label">
                    {props.label}
                </p>
            </div>
            <div id="text-input-text-field-container">
                {
                    props.monetary &&
                    (
                        <p id="text-input-currency">
                            R$
                        </p>
                    )
                }
                <input 
                    id="text-input-text-field" 
                    onChange={(e) => {handleOnChange(e.target.value)}}
                    onKeyDown={(e) => {handleOnKeyPressed(e.key)}}
                    disabled={props.disabled} 
                    value={value}
                />
            </div>
        </div>
    )
}
