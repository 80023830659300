import React, {useState} from "react"

import "./contentPagination.css"


export default function ContentPagination(props: {maxPages: number, onPageChange: Function}) {

    const [currentPage, setCurrentPage] = useState<number>(1)

    function handleNextPag(){
        console.log(props.maxPages)
        if (currentPage < props.maxPages){
            setCurrentPage(currentPage + 1)
            props.onPageChange(currentPage + 1)
        }
    }

    function handlePrevPag(){
        if (currentPage > 1){
            setCurrentPage(currentPage - 1)
            props.onPageChange(currentPage - 1)
        }
    }

    return (
        <ul id="content-pagination-main">
            <li 
                className="content-pagination-element"
                onClick={handlePrevPag}
            >
                Página anterior
            </li>
            <li id="content-pagination-display-element">
                {currentPage}
            </li>
            <li 
                className="content-pagination-element"
                onClick={handleNextPag}
            >
                Proxima página
            </li>
        </ul>
    )
}