import React, { useEffect } from "react"

import "./result.css"

import { useNavigate } from "react-router-dom"
import PaymentSuccessAnimation from "components/animations/paymentSuccessAnimation"
import PaymentFailAnimation from "components/animations/paymentFailAnimation"

export default function Result(props: {success: boolean}){

    const navigate = useNavigate()

    function handleNavigate(){
        navigate("/carrinho")
    }

    return (
        <>
            {
                props.success &&
                (
                    <div id="cart-result-main">
                        <div id="cart-result-animation">
                            <PaymentSuccessAnimation
                                style={{
                                    display: "flex", height: "100%"
                                }}
                            />
                        </div>
                        <div id="cart-result-info">
                            <p id="card-result-info1-txt">
                                Parabens, sua compra foi aprovada
                            </p>
                            <p id="card-result-info2-txt">
                                Acesse seu perfil para acompanhar cada etapa
                            </p>
                        </div>
                        <div id="cart-result-btn">
                            <div id="cart-result-profile-btn" onClick={handleNavigate}>
                                <p id="cart-result-profile-btn-txt">
                                    Pedidos
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !props.success &&
                (
                    <div id="cart-result-main">
                        <div id="cart-result-animation">
                            <PaymentFailAnimation
                                style={{
                                    display: "flex", height: "100%"
                                }}
                            />
                        </div>
                        <div id="cart-result-info">
                            <p id="card-result-info1-txt">
                                Ops, parece que houve um problema
                            </p>
                            <p id="card-result-info2-txt">
                                Tente novamente o pagamento por meio do botão abaixo
                            </p>
                        </div>
                        <div id="cart-result-btn">
                            <div id="cart-result-profile-btn" onClick={handleNavigate}>
                                <p id="cart-result-profile-btn-txt">
                                    Voltar ao pagamento
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}