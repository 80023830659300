import React, {useState, Dispatch, createContext} from "react"

import "./authentication.css"


import Login        from "./components/login/login"
import Register     from "./components/register/register"
import Recover      from "./components/recover/recover"
import ConfirmEmail from "./components/confirmEmail/confirmEmail"
import LoadingAnimation from "components/animations/loadingAnimation"

interface IAuthUserContext {
    user: IUser
    setUser: Dispatch<React.SetStateAction<IUser>>
    navigate: Function
}
interface IUser {
    email: string
    password: string
}

interface INavigation {
    login: boolean
    register: boolean
    emailConfirm: boolean
    recover: boolean
}

export const AuthUserContext = createContext<IAuthUserContext | undefined>(undefined);

export default function Authentication(props: {onAuth?: Function}){

    const [runAnimation, setRunAnimation] = useState<boolean>(false)

    const [user, setUser] = useState<IUser>({
        email: "",
        password: ""
    })

    const [navigationController, setNavigationController] = useState<INavigation>({
        login:        true,
        register:     false,
        emailConfirm: false,
        recover:      false
    })


    function handleNavigation(element: string){
        for (const key in navigationController){
            if (navigationController.hasOwnProperty(key)){
                const keyTyped = key as keyof INavigation
                
                if (keyTyped === element){
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    function handleWaitResponse(value: boolean, component: string){
        if (value){
            setNavigationController((prev) => ({
                ...prev,
                [component]: false
            }))
            setRunAnimation(true)
        } else {
            setRunAnimation(false)
            setNavigationController((prev) => ({
                ...prev,
                [component]: true
            }))
        }
    }
    
    return (
        <AuthUserContext.Provider 
            value={{
                user: user,
                setUser: setUser,
                navigate: handleNavigation
            }}
        >
            <div id="cart-authentication-main">
                <div id="cart-authentication-adversiment">
                    {
                        (navigationController.login || navigationController.register) &&
                        (
                            <p id="cart-authentication-adversiment-txt">
                                Para prosseguir com a compra, autentique-se ou cadastre-se
                            </p>
                        )
                    }
                    {
                        navigationController.emailConfirm &&
                        (
                            <p id="cart-authentication-adversiment-txt">
                                Digite o código recebido no seu email para confirmar sua conta
                            </p>
                        )
                    }
                    {
                        navigationController.recover &&
                        (
                            <p id="cart-authentication-adversiment-txt">
                                Digite o seu email para enviarmos um link de redefinição de senha
                            </p>
                        )
                    }
                </div>
                <div id="cart-authentication">
                    {   
                        navigationController.login &&
                        (
                            <Login
                                onWaitResponse={handleWaitResponse}
                            />
                        )
                    }
                    {   
                        navigationController.register &&
                        (
                            <Register
                                onWaitResponse={handleWaitResponse}
                            />
                        )
                    }
                    {
                        navigationController.emailConfirm &&
                        (
                            <ConfirmEmail
                                onWaitResponse={handleWaitResponse}
                            />
                        )
                    }
                    {
                        navigationController.recover &&
                        (
                            <Recover
                                onWaitResponse={handleWaitResponse}
                            />
                        )
                    }
                    {
                        runAnimation &&
                        (
                            <LoadingAnimation
                                style={{width: "200px", height: "200px", position: "absolute", top: "45%"}}
                            />
                        )
                    }
                </div>
            </div>
        </AuthUserContext.Provider>
    )
}