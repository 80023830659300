import React, {useState, useEffect, ReactNode, createContext} from "react"

import { AxiosResponse, AxiosError } from "axios"
import { apiCaller } from "api/apiCaller"
import { checkIsAdmin } from "misc/encrypt"

interface IAuthProvider {
    isAuthenticated: boolean
    isAdministrator: boolean
    setToken: Function
    getToken: Function
    rmToken: Function
    auditToken: Function
    setAuthenticated: Function
}

export const AuthContext = createContext<IAuthProvider | undefined>(undefined);

export default function AuthProvider(props: {children?: ReactNode}): JSX.Element{

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isAdministrator, setIsAdministrator] = useState<boolean>(false)

    useEffect(() => {
        auditToken()
    }, [])

    function setToken(token: string) {
        setIsAuthenticated(true)
        localStorage.setItem("JWTPayload", token);
    }

    function getToken(){
        const token = localStorage.getItem("JWTPayload")
        if (token){
            return token
        }
    }

    function rmToken(){
        localStorage.removeItem("JWTPayload")
        localStorage.removeItem("_USID")

        if (localStorage.getItem("JWTPayload") === null){
            setIsAuthenticated(false)
            setIsAdministrator(false)
            return true
        } else {
            return false
        }
    }

    async function auditToken(){
        const token = localStorage.getItem("JWTPayload");

        if (token === null){
            setIsAuthenticated(false)
            setIsAdministrator(false)
        } else {
            const role = localStorage.getItem("_USID");

            if (role === null) {
                apiCaller.get("/accounts/role",
                    {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    }).then(async (response: AxiosResponse) => {
                        const role = response.data.role;
                        const isAdmin = await checkIsAdmin(role);
                        
                        if (isAdmin) {
                            setIsAdministrator(true)
                            setIsAuthenticated(true)
                        } else {
                            setIsAdministrator(false)
                            setIsAuthenticated(true)
                        }

                        localStorage.setItem('_USID', role);
                    }).catch((error: AxiosError) => {
                        setIsAuthenticated(false)
                        setIsAdministrator(false)
                    })
            } else {
                const isAdmin = await checkIsAdmin(role);
                
                if (isAdmin) {
                    setIsAdministrator(true)
                    setIsAuthenticated(true)
                } else {
                    setIsAdministrator(false)
                    setIsAuthenticated(true)
                }
            }
        }
    }

    function setAuthenticated(admin: boolean){
        setIsAdministrator(admin)
        setIsAuthenticated(true)
    }

    return (
        <AuthContext.Provider value={
            {
                isAuthenticated: isAuthenticated,
                isAdministrator: isAdministrator,
                setToken: setToken,
                getToken: getToken,
                rmToken: rmToken,
                auditToken: auditToken,
                setAuthenticated: setAuthenticated
            }
        }>
            {props.children}
        </AuthContext.Provider>
    )
}