import React from "react"

import "./footer.css"

import FooterLink from "./components/footerLink"

import InstagramIcon from "assets/icons/instagram.png"

export default function Footer(){
    return (
        <div id="footer-main">
            <div id="footer-social">
                {/* <div id="footer-social-label-container">
                    <p id="footer-social-label-txt">
                        Nos siga nas redes sociais
                    </p>
                </div>
                <div id="footer-social-links">
                    <a 
                        className="footer-social-link"
                        href="">
                        <img 
                            className="footer-social-link-img"
                            src={InstagramIcon} 
                            alt="" 
                        />
                    </a>
                </div> */}
            </div>
            <div id="footer-info">
                <div id="footer-info-map">
                    <p className="footer-info-label-txt">
                        Mapa do site
                    </p>
                    <FooterLink
                        link="/login"
                        name="Login"
                    />
                    <FooterLink
                        link="/login"
                        name="Promoções"
                    />
                    <FooterLink
                        link="/login"
                        name="Novidades"
                    />
                    <FooterLink
                        link="/login"
                        name="Meninos"
                    />
                    <FooterLink
                        link="/login"
                        name="Meninas"
                    />
                </div>
                <div id="footer-info-institutional">
                    <p className="footer-info-label-txt">
                        Institucional
                    </p>
                    <FooterLink
                        link="/login"
                        name="Suporte"
                    />
                    <FooterLink
                        link="/login"
                        name="Sobre nós"
                    />
                    <FooterLink
                        link="/login"
                        name="Trocas e devoluções"
                    />
                    <FooterLink
                        link="/login"
                        name="Politica de privacidade"
                    />
                    <FooterLink
                        link="/login"
                        name="Regras do frete"
                    />
                    <FooterLink
                        link="/login"
                        name="Envio e entrega"
                    />
                </div>
                <div id="footer-info-payment-methods">
                    <p className="footer-info-label-txt">
                        Metodos de pagamento
                    </p>
                </div>
                <div id="footer-info-customer-support">
                    <p className="footer-info-label-txt">
                        Suporte
                    </p>
                </div>
            </div>
            <div id="footer-developer">

            </div>
        </div>
    )
}