import React, { useEffect, useState, useId } from "react"

import "./dropdown.css"

import Option from "./components/option"

export default function Dropdown(
    props: {
        options: string[], 
        label: string, 
        default?: string, 
        hideOnClick?: boolean, 
        showOnClick?: boolean,
        onSelect: Function
    }
){

    const componentId = useId()

    const [options, setOptions] = useState<string[]>([])
    const [selectedOption, setSelectedOption] = useState<string>("")

    const [width,          setWidth         ] = useState<number>(0)
    const [headerHover,    setHeaderHover   ] = useState<boolean>(false)
    const [bodyHover,      setBodyHover     ] = useState<boolean>(false)
    const [renderDropDown, setRenderDropDown] = useState<boolean>(false)

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [position, setPosition] = useState<any>({positionX: 0, positionY: 0})
    
    useEffect(() => {
        setOptions(props.options)
    },[props.options])

    useEffect(() => {
        if (props.default){
            setSelectedOption(props.default)
        } else {
            setSelectedOption("")
        }
    },[props.default])

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    },[]);

    useEffect(() => {
        setRenderDropDown(false)
    }, [windowDimensions]);
    
    
    useEffect(() => {
        const elemento = document.getElementById(`basic-dropdown-${componentId}-btn`);
        if (elemento != null) {
            const rect = elemento.getBoundingClientRect();
            setWidth(rect.width * 1)
            setPosition({
                X: rect.left,
                Y: rect.top + rect.height
            })
        }
        
    }, [window.innerWidth,window.innerHeight])
    
    useEffect(() => {
        if (headerHover || bodyHover){
            setRenderDropDown(true)
        } else {
            setRenderDropDown(false)
        }
    }, [bodyHover, headerHover])

    
    function handleUnhover(element: string){
        if (element === "header"){
            setHeaderHover(false)
        } 
        if (element === "body"){
            setBodyHover(false)
        }
    }

    function handleHover(element: string){
        if (element === "header"){
            if (props.showOnClick === undefined || props.showOnClick === false){
                setHeaderHover(true)
            }
        } 
        if (element === "body"){
            setBodyHover(true)
        }
    }

    function handleShow(){
        setHeaderHover(true)
    }

    function handleSelect(option: string){
        setSelectedOption(option)
        setRenderDropDown(false)
        props.onSelect(option)
    }

    return (
        <div id="basic-dropdown-main">
            <div className="basic-dropdown-btn">  
                <div id="basic-dropdown-label-container">
                    <p id="basic-dropdown-label-txt">
                        {props.label}
                    </p>
                </div>
                <div 
                    className="basic-dropdown-value-container" 
                    id={`basic-dropdown-${componentId}-btn`}
                    onMouseEnter={() => {handleHover("header")}}
                    onMouseLeave={() => {handleUnhover("header")}}
                    onMouseDown={() => {handleShow()}}
                >
                    <p id="basic-dropdown-value-txt">
                        {selectedOption}
                    </p>
                </div>
            </div>
                <ul id="basic-dropdown-list" 
                    style={{display: renderDropDown? "flex": "none", top: position.Y, left: position.X, width: width}}
                    onMouseEnter={() => {handleHover("body")}}
                    onMouseLeave={() => {handleUnhover("body")}}
                >
                    {
                        options.map((option) => {
                            return (
                                <Option
                                    key={option}
                                    value={option}
                                    onSelect={handleSelect}
                                />
                            )
                        })
                    }
                </ul>
        </div>
    )
}