import React, {useState, useEffect, useContext} from "react"

import "./sizeList.css"

import { IProduct, IProductVariation, ISize } from "interfaces/product/IProduct"
import Size                                   from "./components/size/size"
import { ProductContext } from "pages/admin/product/product/product" 

export default function SizeList(props: {data: ISize[]}){

    const productContext = useContext(ProductContext)

    function handleCreateSize(){
        if (productContext) {
            productContext.setProduct((prevProduct) => {
                const updatedVariations = [...prevProduct!.variations];
                
                const updatedVariation = {
                    ...updatedVariations[productContext.variationIndex],
                    sizes: [
                        ...updatedVariations[productContext.variationIndex].sizes, 
                        {
                            id: "",
                            size: "",
                            quantity: 0,
                            enabled: false
                        }
                    ],
                };
    
                updatedVariations[productContext.variationIndex] = updatedVariation;
    
                return {
                    ...prevProduct!,
                    variations: updatedVariations,
                };
            });
        }
    }

    function handleDeleteSize(index: number){
        if (productContext) {
            productContext.setProduct((prevProduct) => {
                const updatedVariations = [...prevProduct!.variations];
                updatedVariations[productContext.variationIndex].sizes.splice(index, 1);
                return {
                    ...prevProduct!,
                    variations: updatedVariations,
                };
            });
        }
    }

    function handleChange(index: number, field: string, value: string | boolean | number){
        if (productContext) {
            productContext.setProduct((prevProduct) => {
                const updatedVariations = [...prevProduct!.variations];
                const updatedVariation = { ...updatedVariations[productContext.variationIndex] };
                const updatedSizes = [...updatedVariation.sizes];
                const updatedSize = { ...updatedSizes[index] };
    
                if (field === "enabled" && typeof value === "boolean"){
                    updatedSize["enabled"] = value;
                } 
                else if (field === "size" && typeof value === "string"){
                    updatedSize["size"] = value;
                }
                else if (field === "quantity" && typeof value === "number"){
                    updatedSize["quantity"] = value;
                }
    
                updatedSizes[index] = updatedSize;
                updatedVariation.sizes = updatedSizes;
                updatedVariations[productContext.variationIndex] = updatedVariation;

                return {
                    ...prevProduct!,
                    variations: updatedVariations,
                };
            });
        }
    }

    return (
        <ul id="admin-product-size-list-main">
            <li 
                key={-1}
                id="admin-product-size-list-new-btn" 
                onClick={handleCreateSize}
            >
                <p id="admin-product-size-list-new-btn-txt">
                    Criar novo tamanho
                </p>
            </li>
            {
                props.data.map((size, index) => {
                    return (
                        <Size
                            key={index}
                            index={index}
                            data={size}
                            onChange={handleChange}
                            onDelete={handleDeleteSize}
                        />
                    )
                })
            }
        </ul>
    )
}