import axios from "axios"
import ICity from "interfaces/address/ICity";

export default async function apiIBGECity(state: string) {
    try {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`);
        const mappedData: ICity[] = response.data.map((city: any) => ({
            id: city.id,
            name: city.nome
        }));
        return mappedData;
    } catch (error) {
        console.error('api error:', error);
        throw error
    }
}