import React, { useState, useEffect, useContext, createContext, Dispatch} from "react"

import "./access.css"

import { AuthContext } from "context/authProvider"
import { useNavigate } from "react-router-dom"
import Login    from "./components/login/login"
import Register from "./components/register/register"
import Confirm from "./components/confirm/confirm"
import Recover from "./components/recover/recover"

interface IAccessContext {
    user: IUser
    setUser: Dispatch<React.SetStateAction<IUser>>
    navigate: Function
}

interface IUser {
    email: string
    password: string
}
interface INavigation {
    login: boolean
    register: boolean
    recover: boolean
    confirm: boolean
}

export const AccessContext = createContext<IAccessContext | undefined>(undefined);

export default function Access(){

    const authContext = useContext(AuthContext)
    
    const navigate = useNavigate()
    
    const [screenHeight, setScreenHeight] = useState<number>(0)

    const [user, setUser] = useState<IUser>({
        email: "",
        password: ""
    })

    const [navigationController, setNavigationController] = useState<INavigation>({
        login: true,
        register: false,
        confirm: false,
        recover: false
    })

    
    useEffect(() => {
        let height = window.screen.availHeight
        height = height - (window.outerHeight - window.innerHeight)
        setScreenHeight(height)
    },[window.innerHeight])

    useEffect(() => {
        /* if the user is already logged in, they're redirected to main page */
        if (authContext?.isAuthenticated){
            navigate("/")
        }
    },[])

    function handleNavigation(element: string){
        for (const key in navigationController){
            if (navigationController.hasOwnProperty(key)){
                const keyTyped = key as keyof INavigation
                
                if (keyTyped === element){
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    return (
        <AccessContext.Provider 
            value={{
                user: user,
                setUser: setUser,
                navigate: handleNavigation
            }}
        >
            <div id="access-main" style={{height: screenHeight}}>
                {
                    navigationController.login &&
                    (
                        <Login/>
                    )
                }
                {
                    navigationController.register &&
                    (
                        <Register/>
                    )
                }
                {
                    navigationController.confirm &&
                    (   
                        <Confirm/>
                    )
                }
                {
                    navigationController.recover &&
                    (   
                        <Recover/>
                    )
                }
            </div>
        </AccessContext.Provider>
    )
}