import React, {useState, useEffect, useContext} from "react"

import "./search.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"
import ProductCard from "components/productCard/productCard"
import { useParams } from "react-router-dom"
import { apiCaller } from "api/apiCaller"

import { IProduct } from "interfaces/product/IProduct"
import { AxiosError, AxiosResponse } from "axios"

export default function Search(){


    const params = useParams()

    const [products, setProducts] = useState<IProduct[]>([])

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("product-search-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])

    useEffect(() => {
        console.log(params)
        if (params.searchstring){
            apiCaller.post("/products/find", {
                featured: false,
                enabled: true,
                name: params.searchstring
            }).then((response: AxiosResponse) => {
                setProducts(response.data.data)
            }).catch((error: AxiosError) => {
    
            })
        }
    },[])

  
    return (
        <>  
            <Header/>
            <div id="product-search-template-main">
                <div id="product-search-template-content">
                    <div id="product-search-template-header">
                        <p id="product-search-header-txt">
                            {`Resultados para "${params.searchstring}"`}
                        </p>
                    </div>
                    <ul id="product-search-template-list" style={{minHeight: screenHeight}}>
                        {
                            products.map((product) => (
                                product.variations.map((variation) => {
                                    return (
                                        <ProductCard
                                            key={product.id + variation.id}
                                            data={{
                                                id: product.id + variation.id,
                                                name: product.name,
                                                url: product.url,
                                                description: product.description,
                                                enabled: product.enabled,
                                                featured: product.featured,
                                                categoryId: product.categoryId,
                                                subcategoryId: product.subcategoryId,
                                                variations: [variation]
                                            }}
                                        />
                                    )
                                })
                            ))
                        }
                    </ul>
                </div>
            </div>
            <Footer/>
        </>
    );
}