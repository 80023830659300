import React, { useState, createContext, Dispatch } from "react"

import "./userPersonalData.css"

import UserProfileTemplate from "../components/userPageTemplate/userPageTemplate"
import PersonalDataIcon    from "assets/icons/pages/userPersonalData/pointer-screen.png"
import PersonalData        from "./components/personalData/personalData"
import PersonalDataEdit    from "./components/personalDataEdit/personalDataEdit"

interface INavigation {
    data: boolean
    edit: boolean
}

interface IProfileDataContext {
    fieldToEdit: string,
    setFieldToEdit: Dispatch<React.SetStateAction<string>>
    navigate: Function
}

export const ProfileDataContext = createContext<IProfileDataContext | undefined>(undefined);

export default function UserPersonalData(){

    const [navigationController, setNavigationController] = useState<INavigation>({
        data: true,
        edit: false
    })

    const [fieldToEdit, setFieldToEdit] = useState<string>("")


    function handleNavigation(element: string){
        for (const key in navigationController){
            if (navigationController.hasOwnProperty(key)){
                const keyTyped = key as keyof INavigation

                if (keyTyped === element){
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    return (
        <ProfileDataContext.Provider value={
            {
                fieldToEdit: fieldToEdit,
                setFieldToEdit: setFieldToEdit,
                navigate: handleNavigation
            }
        }>
            <UserProfileTemplate image={PersonalDataIcon} name="Dados Pessoais" navigationUrl="/perfil/dados">
                {
                    navigationController.data &&
                    (
                        <PersonalData/>
                    )
                }
                {
                    navigationController.edit &&
                    (
                        <PersonalDataEdit/>
                    )
                }
            </UserProfileTemplate>
        </ProfileDataContext.Provider>
    )
}