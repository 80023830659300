import React, {useContext} from "react"

import "./sizeSelector.css"

import Size from "./components/size"
import { ISize } from "interfaces/product/IProduct"

export default function SizeSelector(props: {sizes: ISize[], /*onSizeSelect: Function*/}){

    return (
        <ol id="product-details-size-selector-main">
            {
                props.sizes.map(size => {
                    return (
                        <Size 
                            key={size.size} 
                            data={size}
                        />
                    )
                })
            }
        </ol>
    )
}