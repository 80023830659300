import React, { useState, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"

import "./confirm.css"

import useExecute from "hooks/useExecute"
import Logo                  from "assets/logo/logoTransparentBackground2.png"
import TextField    from "components/inputs/textfieldUnderline/textfieldUnderline"
import { AccessContext } from "pages/access/access"
import { AuthContext } from "context/authProvider"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"

export default function Confirm(props: {}){

    const accessContext = useContext(AccessContext)
    const authContext   = useContext(AuthContext)

    const navigate = useNavigate()

    const [code, setCode  ] = useState<string>("");
    const [error, setError] = useState<string>("")

    useExecute(4.6, error, handleResetError)

    function handleCodeChange(value: string) {
        setCode(value);
    }
    
    function handleCodeConfirm(){
        if (!code) {
            setError("Código inválido")
        } else {
            apiCaller.post("/auth/confirm", 
                {
                    email_confirmation_code: code
                },
                {
                    params: {
                        email: accessContext?.user.email
                    }
                }
            ).then((response: AxiosResponse) => {
                apiCaller.post("/auth/login",
                {
                    email: accessContext?.user.email,
                    password: accessContext?.user.password
                }
                ).then((response: AxiosResponse) => {
                    authContext?.setToken(response.data.data)
                    navigate("/")
                })
            }).catch((error: AxiosError) => {
                setError("Código inválido")
            })
        }
    }
    
    function handleResendCode(){
        apiCaller.post("/auth/resend", 
            {

            },
            {
                params: {
                    email: accessContext?.user.email
                }
            }
        ).then((response: AxiosResponse) => {
            console.log(response)
        }).catch((error: AxiosError) => {

        })
    }

    function handleResetError(){
        setError("")
    }
    
    return (
            <div id="confirm-container">
                <div id="confirm-logo-container" >
                    <Link id="confirm-logo-link" to={"/"} draggable={false}>
                        <img id="confirm-logo" src={Logo} alt="" draggable={false}/>
                    </Link>
                </div>
                <div id="confirm-content">
                    <div className="confirm-input-container">
                        <p id="confirm-text">
                            Digite o código recebido no seu email para confirmar sua conta
                        </p>
                    </div>
                    <div className="confirm-input-container">
                        <TextField 
                            label="Código"
                            onValueChange={handleCodeChange}
                            onSelected={handleResetError}
                            onKeyDown={handleCodeConfirm}
                        />
                    </div>
                </div>
                <div id="confirm-warning-message">
                    {
                        error &&
                        (
                            <p id="confirm-warning-message-txt">
                                {error}
                            </p>

                        )
                    }
                </div>   
                <div id="confirm-btns">
                    <div id="confirm-btns-confirm" onClick={handleCodeConfirm}>
                        <p id="confirm-btns-confirm-txt">
                            Confirmar
                        </p>
                    </div>
                    <p id="confirm-btns-resend" onClick={handleResendCode}>
                        Reenviar código
                    </p>
                </div>
            </div>
    )
}