import React, {useState, useEffect, useContext } from "react"
import "./address.css"

import { apiCaller } from "api/apiCaller"
import CartAddressListElement from "./components/cartAddressListElement"
import IShipmentAddress from "interfaces/IShipmentAddress"
import CreateAddress from "./components/createAddress/createAddress"
import { CartContext } from "pages/user/cart/userCart"
import { AuthContext } from "context/authProvider"
import LoadingAnimation from "components/animations/loadingAnimation"
import { AxiosError, AxiosResponse } from "axios"
import useExecute from "hooks/useExecute"

interface ISelectedAddress {
    address: IShipmentAddress
    selected: boolean
}

export default function Address(){

    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)

    const [loaded,             setloaded            ] = useState<boolean>(false)
    const [isRunningAnimation, setIsRunningAnimation] = useState<boolean>(false)
    const [addressList,        setAddressList       ] = useState<IShipmentAddress[]>([])
    const [error,              setError             ] = useState<string>("")

    useExecute(4.6, error, handleResetError)

    useEffect(() => {
        let triggerAnimation: boolean = true
        setTimeout(() => {
            if (triggerAnimation){
                setloaded(false)
                setIsRunningAnimation(true)
            }
        }, 200)

        apiCaller.get("/addresses/",
            {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }
        ).then((response: AxiosResponse) => {   
            setAddressList(response.data.data)
        }).catch((error: AxiosError) => {

        }).finally(() => {
            triggerAnimation = false
            setloaded(true)
            setIsRunningAnimation(false)
        })
    },[])


    function handleAddressUpdate(newAddress: IShipmentAddress){
        setAddressList(
            addressList.map(address => {
                if (address.id === newAddress.id){
                    return newAddress
                } else {
                    return address
                }
            })
        )
    }

    function handleAddressSelect(id: string){
        setAddressList(
            addressList.map(address => {
                if (address.id === id) {
                    return {
                        ...address, default: true
                    }
                } else {
                    return { ...address, default: false }
                }
            })
        )
    }

    function handleShipmentAddressSelect(){
        const selectedAddress = addressList.find((address) => {
            if (address.default){
                return address
            }
        })

        if (selectedAddress){
            cartContext?.setOrder((prevOrder) => ({
                ...prevOrder,
                addressId: selectedAddress.id
            }))

            cartContext?.setStatusTracker((prevStatus) => ({
                ...prevStatus,
                payment: true
            }))

            cartContext?.handleNavigation("payment")
        } else {
            setError("aaa")
        }
    }

    function handleResetError(){
        setError("")
    }

    return (
        <div id="cart-address-main" style={{justifyContent: loaded? "flex-start": "center"}}>
            {   
                addressList.length >= 1 && loaded &&
                (
                    <>
                        <div id="cart-address-header">
                            <div 
                                id="cart-address-btn-select"
                                onClick={handleShipmentAddressSelect}
                            >
                                <p id="cart-address-btn-select-txt">
                                    Continuar
                                </p>
                            </div>
                            <div id="cart-address-warning">
                                {
                                    error && 
                                    (
                                        <p id="cart-address-header-warning-txt">
                                            Escolha um endereço para o envio
                                        </p>
                                    )
                                }
                            </div>
                        </div>
                        <ul id="cart-address-list">
                            {
                                addressList.map(address => {
                                    return (
                                        <CartAddressListElement
                                            key={address.id}
                                            data={address}
                                            isSelected={address.default}
                                            onUpdate={handleAddressUpdate}
                                            onSelect={handleAddressSelect}
                                        />
                                    )
                                })
                            }
                        </ul>
                    </>

                )
            }
            {
                addressList.length < 1 && loaded &&
                (
                    <CreateAddress/>
                )
            }
            {
                isRunningAnimation && 
                (
                    <LoadingAnimation
                    style={{width: "200px", height: "200px", position: "absolute", top: "45%"}}
                    />
                )
            }
        </div>
    )
}