import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"

import "./header.css"

import Logo              from "assets/logo/logoTransparentBackground2.png"
import SearchField       from "../searchField/searchField"
import HeaderMenu        from "./components/menu/headerMenu"
import Cart              from "./components/cart/cart"
import User              from "./components/user/user"
import Unauthenticated   from "./components/unauthenticated/unauthenticated"
import Admin             from "./components/admin/admin"
import MenuBtn           from "./components/menuBtn/menuBtn"
import { GlobalContext } from "context/globalProvider"
import { AuthContext }   from "context/authProvider"

export default function Header(){

    const globalContext = useContext(GlobalContext)
    const authContext = useContext(AuthContext)

    const [renderMobileMenu, setRenderMobileMenu] = useState<boolean>(false)

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")
            const headerElement = document.getElementById("header-main")

            if (rootElement && headerElement) {
                const rootHeight = rootElement.getBoundingClientRect().height
                const headerHeight = headerElement.getBoundingClientRect().height
                const newContentHeight = rootHeight - headerHeight

                const contentElement = document.getElementById("header-mobile-menu")
                if (contentElement) {
                    contentElement.style.height = `${newContentHeight}px`
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])


    function handleRenderMobileMenu(){
        if (renderMobileMenu) {
            setRenderMobileMenu(false)
        } else {
            setRenderMobileMenu(true)
        }
    }

    return (
        <>
            <div id="header-main">
                <div id="header-shadow">
                    <Link id="header-logo-link" to="/#" draggable={false}>
                        <img 
                            id="header-logo" 
                            src={Logo} 
                            alt="" 
                            draggable={false}
                        />
                    </Link>
                    {/* <div id="header-logo-container">
                        <a id="header-logo-link" href="/#" draggable={false}>
                            <img id="header-logo" src={Logo} alt="" draggable={false}/>
                        </a>
                    </div> */}
                    <div id="header-general-fields-container">
                        <div id="header-general-fields-search">
                            <div id="header-general-fields-search-container">
                                <SearchField placeholder="O que você precisa?"/>
                            </div>
                        </div>
                        <div id="header-general-fields-category-container">
                            {
                                globalContext?.categories.map((category) => {
                                    return (
                                        <HeaderMenu
                                            key={category.id}
                                            categoryName={category.name}
                                            entries={category.subcategories}
                                        />
                                    )
                                })
                            }
                            <HeaderMenu
                                categoryName="Promoções"
                            />
                        </div>
                    </div>
                        <div id="header-user-fields-container">
                            {   
                                !authContext?.isAuthenticated && 
                                (
                                    <Unauthenticated/>
                                )
                            }
                            {
                                authContext?.isAuthenticated && authContext?.isAdministrator &&
                                (
                                    <Admin/>
                                )
                            }
                            {
                                authContext?.isAuthenticated && !authContext.isAdministrator &&
                                (
                                    <User/> 
                                )
                            }
                            <MenuBtn onClick={handleRenderMobileMenu}/>
                            <Cart/>
                        </div>
                </div>
            </div>
            <div id="header-mobile-menu" className={renderMobileMenu? "header-mobile-menu-show": "header-mobile-menu-hide"}>
                <div id="header-mobile-menu-search-container">
                    <div id="header-mobile-menu-search">
                        <SearchField 
                            placeholder="O que você precisa?"
                            showIcon={false}
                        />
                    </div>
                </div>
                <ul id="header-mobile-menu-categories-container">
                        {
                            globalContext?.categories.map((category) => {
                                return (
                                    <li key={category.id} className="header-mobile-menu-category-container">
                                        <ul className="header-mobile-menu-category">
                                            <a 
                                                key={200} 
                                                className="header-mobile-menu-category-title"
                                                href={`/categoria/${category.name.toLowerCase()}`}
                                                draggable={false}
                                            >
                                                <p className="header-mobile-menu-category-title-txt">
                                                    {category.name}
                                                </p>
                                            </a>
                                            {
                                                category.subcategories.map((sub) => {
                                                    return (
                                                        <a 
                                                            key={sub.id} 
                                                            className="header-mobile-menu-category-entry"
                                                            href={`/categoria/${category.name.toLowerCase()}/${sub.name.toLocaleLowerCase()}`}
                                                            draggable={false}
                                                        >
                                                            <p className="header-mobile-menu-category-entry-txt">
                                                                {sub.name}
                                                            </p>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                )   
                            })
                        }
                </ul>
            </div>
        </>
    )
}