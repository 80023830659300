import React, {useState, useEffect, ChangeEvent} from "react"

import "./imageUpload.css"


export default function ImageUpload(props: {parent?: string, onUploadImg: Function}){

    function handleFileChange(files: any){
        const file: File = files[0];
        
        if (file) {
            const filename = file.name.split(".")
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                if (typeof base64String === "string") {
                    props.onUploadImg(base64String, filename[1])
                }
            }

            reader.readAsDataURL(file);
        }
    }

    // function handleFileChange(files: FileList | null) {
    //     const file = files ? files[0] : null;
    //     if (file) {
    //         const reader = new FileReader();
            
    //         // Define a função que será chamada quando a leitura estiver completa
    //         reader.onloadend = () => {
    //             const base64String = reader.result;
                
    //             // Verifica se o resultado é uma string antes de passar para onUploadImg
    //             if (typeof base64String === "string") {
    //                 props.onUploadImg(base64String);
    //             }
    //         };
            
    //         // Inicia a leitura do arquivo e a conversão para base64
    //         reader.readAsDataURL(file);
    //     }
    // }

    function teste(){
        document.getElementById(`file-upload-field${props.parent}`)?.click()
    }

    return (
        <div id="file-upload-main">
            <input 
                id={`file-upload-field${props.parent}`}
                style={{display: "none"}} 
                type="file" 
                onChange={(e) => {handleFileChange(e.target.files)}}
            />
            <div id="file-upload-btn" onClick={teste}>
                <p id="file-upload-btn-txt">
                    Adicionar Imagem
                </p>
            </div>
        </div>
    );
}