import React from "react"
import {Link} from "react-router-dom"

import "./userProfileMenuEntry.css"

import IUserProfileMenuEntry from "../../../../../interfaces/IUserProfileMenuEntry"

export default function UserProfileMenuEntry(props: {data: IUserProfileMenuEntry}){
    return (
        <li id="user-profile-menu-entry-main">
            <Link id="user-profile-menu-entry-link" to={props.data.url} draggable={false}>
                <div id="user-profile-menu-entry-icon-container">
                    <img 
                        id="user-profile-menu-entry-icon"
                        src={props.data.image} 
                        alt=""
                        draggable={false}
                    />
                </div>
                <div id="user-profile-menu-entry-info-container">
                    <p id="user-profile-menu-entry-info-title">
                        {props.data.label}
                    </p>
                    <p id="user-profile-menu-entry-info-description">
                        {props.data.description}
                    </p>
                </div>
            </Link>
        </li>
    )
}