import React from "react"
import { Link } from "react-router-dom"

import "./menuBtn.css"

import MenuIcon from "assets/icons/dots-menu.png"

export default function MenuBtn(props: {onClick: Function}){

    function handleMobileMenu(){
        props.onClick()
    }

    return (
        <div id="header-user-menu-main" onClick={handleMobileMenu}>
            <div id="header-user-menu-menu-btn">
                <img 
                    id="header-user-menu-menu-icon"
                    src={MenuIcon}
                    alt="" 
                />
            </div>
        </div>
    )
}