import React from "react"

import "./cancelOrder.css"

import TextFieldLarge from "components/inputs/textFieldLarge/textFieldLarge"

export default function CancelOrder(props: {id: string, onRefuseCancel: Function}){

    function handleRufuseCancel(){
        props.onRefuseCancel()
    }

    return (
        <div id="cancel-order-modal-main">
            <div id="cancel-order-title">
                <p id="cancel-order-title-txt1">
                    Cancelamento do pedido
                </p>
                <p id="cancel-order-title-txt2">
                    {props.id}
                </p>
            </div>
            <div id="cancel-order-content">
                <div id="cancel-order-content-text-field">
                    <TextFieldLarge
                        label="Mensagem de cancelamento"
                        onValueChange={() => {}}
                    />
                </div>
            </div>
            <div id="cancel-order-btn-container">
                <p id="cancel-order-btn-confirm">
                    Confirmar
                </p>
                <p id="cancel-order-btn-cancel" onClick={handleRufuseCancel}>
                    Cancelar
                </p>
            </div>
        </div>
    )
}