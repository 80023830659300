import React, {useState, useEffect} from "react"

import "./size.css"

import { ISize }           from "interfaces/product/IProduct"
import CheckBoxWithoutText from "components/inputs/checkboxWithoutText/checkboxWithoutText"
import TextInput           from "./components/textField/textInput"
import CloseIcon           from "assets/icons/close.png"

export default function Size(props: {data: ISize, index: number, onChange: Function, onDelete: Function}){

    const [checkboxDisabled, setCheckboxDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (props.data.quantity !== 0 && props.data.size !== ""){
            setCheckboxDisabled(false)
        } else {
            setCheckboxDisabled(true)
        }
    },[props.data.quantity, props.data.size])

    function handleEnableChange(value: boolean){
        props.onChange(props.index, "enabled", value)
    }

    function handleSizeChange(value: string){
        props.onChange(props.index, "size", value)
    }

    function handleQuantityChange(value: string){
        props.onChange(props.index, "quantity", parseInt(value))
    }

    function handleDelete(){
        props.onDelete(props.index)
    }

    return (
        <li id="admin-product-size-list-size-main">
            <div id="admin-product-size-list-size-activated">
                <CheckBoxWithoutText
                    onValueChange={handleEnableChange}
                    value={props.data.enabled}
                    disabled={checkboxDisabled}
                />
            </div>
            <div id="admin-product-size-list-size-size">
                <TextInput
                    placeholder="tamanho"
                    value={props.data.size}
                    onValueChange={handleSizeChange}
                />
            </div>
            <div id="admin-product-size-list-size-stock">
                <TextInput
                    value={props.data.quantity}
                    onValueChange={handleQuantityChange}
                />
            </div>
            <div id="admin-product-size-list-size-btns">
                <img 
                    id="product-item-size-list-size-element-delete-btn"
                    src={CloseIcon} 
                    alt="" 
                    draggable={false}    
                    onClick={handleDelete}
                />
            </div>
        </li>
    )
}