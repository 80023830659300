import React, {useState, useEffect, useContext} from "react"

import "./recover.css"

import useExecute from "hooks/useExecute"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import { CartContext } from "pages/user/cart/userCart"
import { AuthUserContext } from "../../authentication"
import { apiCaller } from "api/apiCaller"
import { Validate } from "misc/validate"
import { AxiosError, AxiosResponse } from "axios"


export default function Recover(props: {onWaitResponse: Function}){

    const cartContext = useContext(CartContext)
    const authUserContext = useContext(AuthUserContext)

    const [email, setEmail] = useState<string>("")
    const [error, setError] = useState<string>("")
    
    useExecute(4.6, error, handleResetError)

    function handleEmailChange(value: string){
        setEmail(value)
    }

    function handleResetError(){
        setError("")
    }

    function handleCancel(){
        authUserContext?.navigate("login")
    }

    function handleRecover(){

        if (email === ""){
            setError("Por favor digite o email")
        }
        else if (!Validate.validateEmail(email)) {
            setError("Email inválido")
        } else {
            let triggerAnimation: boolean = true
            
            setTimeout(() => {
                if (triggerAnimation){
                    props.onWaitResponse(true, "recover")
                }
            }, 200)

            console.log(email)
            apiCaller.post("/accounts/recover",
            {
                
            },
            {
                params: {
                    email: email,
                }
            }).then((response: AxiosResponse) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "recover")
                    triggerAnimation = false

                    // authUserContext?.navigate("login")
                } 
            }).catch((error: AxiosError) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "recover")
                    triggerAnimation = false
                }
                setError("Não encontramos nenhuma conta com este email")
            })
        }
    }



    return (
        <div id="cart-authentication-recover-main">
            <div id="cart-authentication-recover-field-container">
                <div className="cart-authentication-recover-field">
                    <TextFieldUnderline 
                        label="Email"
                        onValueChange={handleEmailChange}
                        onSelected={handleResetError}
                    />
                </div>
            </div>
            <div id="cart-authentication-recover-warning">
                {
                    error !== "" &&
                    (
                        <p id="cart-authentication-recover-warning-txt">
                            {error}
                        </p>
                    )
                }
            </div>
            <div id="cart-authentication-recover-btn-container">
                <div id="cart-authentication-recover-btn" onClick={handleRecover}>
                    <p id="cart-authentication-recover-recover-btn-txt">
                        Enviar
                    </p>
                </div>
                <p id="cart-authentication-recover-cancel-btn" onClick={handleCancel}>
                    Cancelar
                </p>
            </div>
        </div>
    )
}
