import React, {useState, useEffect, ReactNode, createContext, useContext, Dispatch} from "react"

import { AxiosResponse, AxiosError } from "axios"
import { apiCaller } from "api/apiCaller"
import { ICategory } from "interfaces/product/IProduct"
import IClient from "interfaces/IClient"
import { AuthContext } from "./authProvider"


interface IGlobalProvider {
    categories: ICategory[]
    setUser: Dispatch<React.SetStateAction<IClient>>
    user: IClient
}

export const GlobalContext = createContext<IGlobalProvider | undefined>(undefined);

export default function GlobalProvider(props: {children?: ReactNode}): JSX.Element{

    const authContext = useContext(AuthContext)

    const [categories, setCategories] = useState<ICategory[]>([])
    const [user,       setUser      ] = useState<IClient>({
        name: "",
        surname: "",
        cpf: "",
        email: "",
        orders: undefined
    })

    useEffect(() => {
        apiCaller.get("/categories/")
        .then((response: AxiosResponse) => {
            const regularCategories = response.data.data.map((category: any) => {
                if (category.isSpecial === false){
                    return {
                        id: category.id,
                        name: category.name,
                        subcategories: category.subcategories
                    }
                }
            })
            setCategories(regularCategories)
        })
        .catch((error: AxiosError) => {

        })

        apiCaller.get("/accounts/", {
            headers: {
                "Authorization": `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            setUser(response.data.data)
            console.log(response.data.data)
        }).catch((error: AxiosError) => {
            // authContext?.rmToken()
        })
        
    },[])

   
    return (
        <GlobalContext.Provider value={
            {
                categories: categories,
                setUser: setUser,
                user: user
            }
        }>
            {props.children}
        </GlobalContext.Provider>
    )
}