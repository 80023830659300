import React from "react"
import { Link } from "react-router-dom"

import "./user.css"

import UserIcon from "assets/icons/user.png"

export default function User(){
    return (
        <div id="header-user-main">
            <Link 
                id="header-user-menu-btn" 
                to={"/perfil"} 
                draggable={false}
            >
                <div id="header-user-menu-btn-txt-container">
                    <p id="header-user-menu-btn-txt">
                        Bem vindo
                    </p>
                </div>
                <img 
                    id="header-user-menu-icon"
                    src={UserIcon}
                    alt="" 
                />
            </Link>
        </div>
    )
}