import React from "react"
import "./entry.css"

import { Link } from "react-router-dom"


import IUserProfileMenuEntry from "interfaces/IUserProfileMenuEntry"

export default function Entry(props: {data: IUserProfileMenuEntry}){
    return (
        <li id="admin-menu-entry-main">
            <Link id="admin-menu-entry-link" to={props.data.url} draggable={false}>
                <div id="admin-menu-entry-icon-container">
                    <img 
                        id="admin-menu-entry-icon"
                        src={props.data.image} 
                        alt=""
                        draggable={false}
                    />
                </div>
                <div id="admin-menu-entry-info-container">
                    <p id="admin-menu-entry-info-title">
                        {props.data.label}
                    </p>
                    <p id="admin-menu-entry-info-description">
                        {props.data.description}
                    </p>
                </div>
            </Link>
        </li>
    )
}