import React, {useEffect, useState} from "react"

export default function useExecute(time: number, trigger: any, callback: Function){

    const [isMounted, setIsMounted] = useState<boolean>(false)

    useEffect(() => {
        if (isMounted){
            const tempoEmMilissegundos = time * 1000;
            // const tempoEmMilissegundos = 4.6 * 1000;
            const timeoutId = setTimeout(callback, tempoEmMilissegundos);
            return () => {
                clearTimeout(timeoutId)
            };
        } else {
            setIsMounted(true)
        }
    }, [trigger])

}