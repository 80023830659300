import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"

import "./recover.css"

import useExecute from "hooks/useExecute"
import Logo                  from "assets/logo/logoTransparentBackground2.png"
import TextField    from "components/inputs/textfieldUnderline/textfieldUnderline"
import PasswordField from "components/inputs/passwordfieldUnderline/passwordfieldUnderline"
import { Validate } from "misc/validate"
import { AccessContext } from "pages/access/access"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"
import LoadingAnimation from "components/animations/loadingAnimation"

export default function Recover(props: {}){

    const accessContext = useContext(AccessContext)

    const [email,     setEmail    ] = useState("");
    const [error,     setError    ] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useExecute(4.6, error, handleResetError)

    function handleEmailChange(value: string) {
        setEmail(value);
    }

    function handleResetError(){
        setError("")
    }

    function handleRecover(){
        
        if (email === ""){
            setError("Por favor digite o email")
        }
        else if (!Validate.validateEmail(email)) {
            setError("Email inválido")
        } else {
            let triggerAnimation: boolean = true
    
            setTimeout(() => {
                if (triggerAnimation){
                    setIsLoading(true)
                }
            }, 200)

            apiCaller.post("/accounts/recover",
            {
                
            },
            {
                params: {
                    email: email,
                }
            }).then((response: AxiosResponse) => {
                if (triggerAnimation) {
                    setIsLoading(false)
                    triggerAnimation = false
                } 
                
                alert("enviou")
            }).catch((error: AxiosError) => {
                if (triggerAnimation) {
                    setIsLoading(false)
                    triggerAnimation = false
                } 
                setError("Não encontramos nenhuma conta com este email")
            }).finally(() => {
                setEmail("")
            })
        }
    }

    function handleCancel(){
        accessContext?.navigate("login")
    }

    return (
            <div id="recover-container">
                <div id="recover-logo-container" >
                    <Link id="recover-logo-link" to={"/"} draggable={false}>
                        <img id="recover-logo" src={Logo} alt="" draggable={false}/>
                    </Link>
                </div>
                {   
                    !isLoading &&
                    (
                        <>
                            <div id="recover-content">
                                <div className="recover-input-container">
                                    <p id="recover-text">
                                        Digite o seu email para enviarmos um link de redefinição de senha
                                    </p>
                                </div>
                                <div className="recover-input-container">
                                    <TextField 
                                        label="E-mail"
                                        onValueChange={handleEmailChange}
                                        onSelected={handleResetError}
                                        onKeyDown={handleRecover}
                                    />
                                </div>
                            </div>
                            <div id="recover-warning-message">
                                {
                                    error &&
                                    (
                                        <p id="recover-warning-message-txt">
                                            {error}
                                        </p>

                                    )
                                }
                            </div>   
                            <div id="recover-btns">
                                <div id="recover-btns-recover" onClick={handleRecover}>
                                    <p id="recover-btns-recover-txt">
                                        Enviar
                                    </p>
                                </div>
                                <p id="recover-btns-cancel" onClick={handleCancel}>
                                    Cancelar
                                </p>
                            </div>
                        </>
                    )
                }
                {
                    isLoading &&
                    (
                        <LoadingAnimation
                            style={{width: "250px", height: "250px", marginTop: "20%"}}
                        />
                    )
                }
                
            </div>
    )
}