import React, { useState, useEffect, useContext } from "react"
import "./animation.css"

import Lottie                 from "lottie-react"
import AddToCartAnimation     from "assets/animations/addToCart.json"
import { useNavigate }        from "react-router-dom"
import { ProductPageContext } from "pages/productPage/productPage"

export default function Animation(){

    const productPageContext = useContext(ProductPageContext)
    const navigate = useNavigate()
    
    const [screenHeight, setScreenHeight] = useState<number>(0)
    
    useEffect(() => {
        let height = window.screen.availHeight
        height = height - (window.outerHeight - window.innerHeight)
        setScreenHeight(height)
    },[window.innerHeight])
    

    function handleAnimationStart(){
        window.scrollTo(0, 0);
        document.documentElement.style.overflow = "hidden";
    }

    function handleAnimationEnd(){
        if (productPageContext){
            productPageContext.setRunAnimation(false)
            document.documentElement.style.overflow = "auto";

            if (productPageContext.buyMode === "now"){
                navigate("/carrinho")
            }
        }
    }

    return (
        <>
            {
                productPageContext?.runAnimation &&
                (
                    <div id="product-page-animation-main" style={{minHeight: screenHeight}}>
                        <Lottie
                            animationData={AddToCartAnimation}
                            autoplay={true}
                            loop={false}
                            initialSegment={[0, 70]}
                            onDOMLoaded={handleAnimationStart}
                            onComplete={handleAnimationEnd}
                            style={{width: "200px"}}
                        />
                    </div>
                )
            }
        </>
    )
}