import React, {useEffect, useState} from "react"

import "./textfieldUnderline.css"


export default function TextField(
    props: {
        label:         string, 
        error?:        string,
        value?:        string
        placeholder?:  string,
        password?:     boolean,
        numeric?:      boolean,
        maxCharacters?:number,
        readonly?:     boolean,
        onValueChange: Function,
        onEdit?:       Function,
        onSelected?:   Function,
        onKeyDown?:    Function
    }
){

    const [value, setValue] = useState<string>("")
    const [selected, setSelected] = useState<boolean>(false)

    useEffect(() => {
        setValue(props.value || "")
    },[props.value])


    function handleEnableValueChange(){
        if (props.onEdit){
            props.onEdit()
        }
    }

    function handleValueChange(value: string){
        if (props.maxCharacters !== undefined){
            if (value.length <= props.maxCharacters){
                if (props.numeric === true){
                    if (/^\d+$/.test(value) === true || value === "") {
                        setValue(value)
                        props.onValueChange(value)
                    }
                } else {
                    setValue(value)
                    props.onValueChange(value)
                }
            }
        } else {
            if (props.numeric === true){
                if (/^\d+$/.test(value) === true || value === "") {
                    setValue(value)
                    props.onValueChange(value)
                }
            } else {
                setValue(value)
                props.onValueChange(value)
            }
        }
    }

    function handleSelected(){
        setSelected(true)
    }

    function handleUnselect(){
        setSelected(false)
    }

    function handleClick(){
        if (props.onSelected){
            props.onSelected()
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>){
        if (event.key === "Enter"){ 
            if (props.onKeyDown){
                props.onKeyDown()
            }
        }
    }

    return (
        <div id="text-field-underline-main" className={(selected? "text-field-underline-main-selected":"text-field-underline-main")}>
            <div id="text-field-underline-text-label-container">
                <p id="text-field-underline-text-label">
                    {props.label}
                </p>    
               {    
                    props.onEdit &&
                    ( 
                        <p id="text-field-underline-text-edit" onClick={handleEnableValueChange}>
                            Editar
                        </p>
                    )
                }
            </div>
            <div id="text-field-underline-text-info">
                <input 
                    id="text-field-underline-text-input" 
                    type="text" 
                    onChange={(e) => {handleValueChange(e.target.value)}} 
                    value={value}
                    placeholder={props.placeholder}
                    onFocus={handleSelected}
                    onBlur={handleUnselect}
                    onMouseDown={handleClick}
                    onKeyDown={(event) => {handleKeyDown(event)}}
                    disabled={props.readonly}
                />
            </div>
        </div>
    )
}