import React, {useState, useEffect, useContext} from "react"

import "./login.css"

import useExecute from "hooks/useExecute"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import PasswordField from "components/inputs/passwordfieldUnderline/passwordfieldUnderline"
import { CartContext } from "pages/user/cart/userCart"
import { AuthContext } from "context/authProvider"
import { AuthUserContext } from "pages/user/cart/components/authentication/authentication"
import { apiCaller } from "api/apiCaller"
import { GlobalContext } from "context/globalProvider"
import { AxiosError, AxiosResponse } from "axios"


export default function Login(props: {onWaitResponse: Function}){

    const globalContext   = useContext(GlobalContext)
    const authContext     = useContext(AuthContext)
    const cartContext     = useContext(CartContext)
    const authUserContext = useContext(AuthUserContext)

    const [email,    setEmail   ] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error,    setError   ] = useState<string>("")

    useExecute(4.6, error, handleResetError)
    
    useEffect(() => {
    },[])

    function handleEmailChange(value: string){
        setEmail(value)
    }

    function handlePasswordChange(value: string){
        setPassword(value)
    }
    
    function handleForgotPassword(){
        authUserContext?.navigate("recover")
    } 

    function handleRegister(){
        authUserContext?.navigate("register")
    }
    
    function handleResetError(){
        setError("")
    }

    function handleLogin(){

        if (email === "" || password === ""){
            setError("Email ou senha inválido")
        } else {
            let triggerAnimation: boolean = true
            
            setTimeout(() => {
                if (triggerAnimation){
                    props.onWaitResponse(true, "login")
                }
            }, 200)

            apiCaller.post("/auth/login", {
                email: email,
                password: password,
            }).then((response: AxiosResponse) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "login")
                    triggerAnimation = false
                    
                    cartContext?.setStatusTracker((prevStatus) => ({
                        ...prevStatus,
                        address: true
                    }))

                    authContext?.setToken(response.data.data)
                    authContext?.setAuthenticated(response.data.isAdmin)
                    cartContext?.handleNavigation("address")

                    apiCaller.get("/accounts/", {
                        headers: {
                            "Authorization": `Bearer ${authContext?.getToken()}`
                        }
                    }).then((response: AxiosResponse) => {
                        globalContext?.setUser(response.data.data)
                        console.log(response.data.data)
                    }).catch((error: AxiosError) => {
                        // authContext?.rmToken()
                    })
                    
                } 
            }).catch((error: AxiosError) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "login")
                    triggerAnimation = false
                }

                const statusCode = error.response?.status

                if (statusCode === 400){
                    authUserContext?.navigate("emailConfirm")
                } 
                else if (statusCode === 401) {
                    setError("Email ou senha inválidos")
                }
            })
        }
    }

    return (
        <div id="cart-authentication-login-main">
            <div id="cart-authentication-login-field-container">
                <div className="cart-authentication-login-field">
                    <TextFieldUnderline 
                        label="Email"
                        onValueChange={handleEmailChange}
                        onSelected={handleResetError}
                    />
                </div>
                <div className="cart-authentication-login-field">
                    <PasswordField   
                        label="Senha"
                        onValueChange={handlePasswordChange}
                        onSelected={handleResetError}
                    />
                </div>
            </div>
            <div id="cart-authentication-warning">
                {
                    error &&
                    (
                        <p id="cart-authentication-warning-txt">
                            {error}
                        </p>
                    )
                }
            </div>
            <div id="cart-authentication-login-btn-container">
                <div id="cart-authentication-login-btn" onClick={handleLogin}>
                    <p id="cart-authentication-login-login-btn-txt">
                        Login
                    </p>
                </div>
                <p id="cart-authentication-login-forgot-password-btn" onClick={handleForgotPassword}>
                    Esqueceu sua senha?
                </p>
                <p id="cart-authentication-login-register-btn" onClick={handleRegister}>
                    Registrar
                </p>
            </div>
        </div>
    )
}
