import React, {useState} from "react"

import "./adminMenu.css"

import Header              from "components/header/header"
import Entry               from "./components/entry/entry"
import PaidOrdersIcon      from "assets/icons/pages/admin/paidOrders.svg"
import SeparatedOrdersIcon from "assets/icons/pages/admin/separatedOrders.png"
import ShippedOrdersIcon   from "assets/icons/pages/admin/shippedOrders.svg"
import ProductListIcon     from "assets/icons/pages/admin/products.svg"
import SettingsIcon        from "assets/icons/pages/admin/settings.png"
import AchivedOrders       from "assets/icons/pages/admin/ArchivedOrders.svg"

export default function AdminMenu(){
    return (
        <>
            <Header/>
            <div id="admin-menu-main">
                <div id="admin-menu-content-link-container">
                    <div id="admin-menu-content-link-label">
                        <p id="admin-menu-content-link-label-txt">
                            Painel de controle
                        </p>
                        {/* <p id="admin-menu-content-link-logout-txt">
                            Sair
                        </p> */}
                    </div>
                    <ul id="admin-menu-content-links">
                        <Entry 
                            data={{
                                description: "Pedidos com pagamento confirmado", 
                                label: "Pedidos Pagos", 
                                url: "/admin/pedidos-pagos",
                                image: PaidOrdersIcon
                            }}
                        />
                        <Entry 
                            data={{
                                description: "Pedidos aguardando serem enviados", 
                                label: "Pedidos prontos", 
                                url: "/admin/pedidos-prontos",
                                image: SeparatedOrdersIcon
                            }}
                        />
                        <Entry 
                            data={{
                                description: "Pedidos despachados em processo de entrega", 
                                label: "Pedidos Enviados", 
                                url: "/admin/pedidos-enviados",
                                image: ShippedOrdersIcon
                            }}
                        />
                        <Entry 
                            data={{
                                description: "Pedidos arquivados e cancelados", 
                                label: "Pedidos Arquivados", 
                                url: "/admin/pedidos-arquivados",
                                image: AchivedOrders
                            }}
                        />
                        <Entry 
                            data={{
                                description: "Lista de todos os produtos", 
                                label: "Produtos", 
                                url: "/admin/produtos",
                                image: ProductListIcon
                            }}
                        />
                        <Entry 
                            data={{
                                description: "Configurações gerais", 
                                label: "Configurações", 
                                url: "/admin/configuracoes",
                                image: SettingsIcon
                            }}
                        />
                        {/* <Entry 
                            data={{
                                description: "Adicione produtos e finalize sua compra", 
                                label: "Carrinho", 
                                url: "/carrinho",
                                image: Cart
                            }}
                        />
                        <Entry 
                            data={{
                                description: "Tem alguma duvida ou problema? entre em contato", 
                                label: "Suporte", 
                                url: "/perfil/suporte",
                                image: Support
                            }}
                        /> */}
                    </ul>
                </div>
            </div>
        </>

    )
}