import React, {useCallback, useEffect, useState, useRef} from "react"

import "./colorInput.css"

import NumberValidade from "misc/numberValidade"

export default function ColorInput(
    props: {
        label: string, 
        value?: string, 
        // disabled?: boolean, 
        // monetary?: boolean,
        // numeric?: boolean
        onValueChange: Function
    }
){

    const pickerRef = useRef<any>()

    const [color, setColor] = useState<string>("");

    useEffect(() => {
        if (!props.value){
            setColor("#000000")
        } else {
            setColor(props.value)
        }
    }, [props.value]);

    // useEffect(() => {
    //     if (props.monetary) {
    //         const zeroAmount = 4 - stack.length;

    //         const str = zeroAmount > 0 ? "0".repeat(zeroAmount) + stack.join('') : stack.join('');
    //         const substring = str.slice(0, -2);
    //         const lastCharacters = str.slice(-2);
            
    //         const concat = substring + '.' + lastCharacters;
        
    //         setValue(parseFloat(concat).toLocaleString('pt-BR'));
    //         setFloatValue(parseFloat(concat));
    //         props.onValueChange(parseFloat(concat));
    //     }
    // }, [stack, props.monetary]);

    function handleOnChange(value: string) {
        setColor(value)
        props.onValueChange(value)
    }

    function handleOpenPicker(){
        pickerRef.current.click()
    }

    // function handleOnKeyPressed(value: string) {
    //     if (props.monetary) {
    //         handleMonetaryValues(value);
    //     }
    // }

    // function handleMonetaryValues(key: string) {
    //     if (key === 'Backspace') {
    //         setStack(stack.slice(0, -1));
    //     } else if (key.match('[0-9]')) {
    //         setStack([...stack, parseInt(key)]);
    //     }
    // }

    // function handleValueChange(value: string){
    //     if (props.numeric === true && props.monetary !== undefined) {
    //         if (NumberValidade.isNumericString(value)){
    //             setValue(value)
    //             props.onValueChange(value)
    //         }
    //     } else {
    //         setValue(value);
    //         props.onValueChange(value)
    //     }
    // }

    return (
        <div id="color-input-main">
            <div id="color-input-text-label-container">
                <p id="color-input-text-label">
                    {props.label}
                </p>
            </div>
            <div 
                id="color-input-text-field-container" 
                onClick={handleOpenPicker}
                style={{backgroundColor: color}}
            >
                <input 
                    ref={pickerRef}
                    id="color-input-text-field" 
                    onChange={(e) => {handleOnChange(e.target.value)}}
                    type="color"
                    value={color}
                />
            </div>
        </div>
    )
}
