import React from "react"

import "./variationSelector.css"

import Variation from "./components/variation"
import { IProductVariation } from "interfaces/product/IProduct"

export default function VariationSelector(props: {data: IProductVariation[]}){
    return (
        <ol id="admin-product-list-product-variation-selector-main">
            {
                props.data.map((variation) => {
                    return (
                        <Variation
                            key={variation.id}
                            data={variation}
                        />
                    )
                })
            }
        </ol>
    )
}