import React, {useState, useEffect, useContext} from "react"

import "./userProfileAddressList.css"

import UserProfileAddressCard from "./components/userProfileAddressCard/userProfileAddressCard"
import Address                from "assets/icons2/hand-package-without-background.png"
import PlusIcon               from "assets/icons/plus.png"
import IShipmentAddress       from "interfaces/IShipmentAddress"
import { AddressContext }     from "pages/user/address/userAddress"
import { AuthContext } from "context/authProvider"
import { apiCaller } from "api/apiCaller"
import { AxiosError, AxiosResponse } from "axios"


export default function UserProfileAddressList(){

    const authContext = useContext(AuthContext)
    const addressContext = useContext(AddressContext)

    useEffect(() => {
        fetchAddresses()
    },[])
    
    
    function fetchAddresses(){
        const token = `Bearer ${authContext?.getToken()}`

        apiCaller.get("/addresses/", {
            headers: {
                "Authorization": token
            }
        })
        .then((response: AxiosResponse) => {
            addressContext?.setAddresses(response.data.data)
        }).catch((error: AxiosError) => {
            // alert(err);
        });
    }
    
    function handleSetAsDefault(id: string){
        apiCaller.put(`/addresses/${id}`, 
        {
            default: true
        }, 
        {
            headers: {
                "Authorization": `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
           fetchAddresses()
        }).catch((error: AxiosError) => {
            console.log(error)
        });
    }
    
    function handleDelete(id: string){

        const token = `Bearer ${authContext?.getToken()}`

        apiCaller.delete(`/addresses/${id}`, 
        {
            headers: {
                "Authorization": token
            }
        })
        .then((response: AxiosResponse) => {
            addressContext?.setAddresses(addressContext.addresses.filter((address) => address.id !== id))

            if (addressContext?.addresses.length && addressContext?.addresses.length > 0){
                apiCaller.put(`/addresses/${addressContext?.addresses[0].id}`, 
                {
                    default: true
                }, 
                {
                    headers: {
                        "Authorization": token
                    }
                }).then((response: AxiosResponse) => {
                    fetchAddresses()
                }).catch((error: AxiosError) => {
                    console.log(error)
                })
            }
        }).catch((error: AxiosError) => {
            alert("Erro ao buscar endereços.");
        })
    }

    function handleCreateAddress(){
        addressContext?.navigate("createAddress")
    }

    function handleEditAddress(id: string){

        if (addressContext){
            const addressToEdit = addressContext.addresses.find((address) => address.id === id)
            if (addressToEdit) {
                addressContext.setAddressToEdit(addressToEdit)
            }

            addressContext.navigate("updateAddress")
        }

    }

    return (
        <ul id="user-profile-address-list">
            {
                addressContext?.addresses && addressContext?.addresses.length >= 1 &&
                (
                    <>
                        <li 
                            key={-1}
                            id="user-profile-address-list-create-address" 
                            onClick={handleCreateAddress}
                        >
                            <img 
                                id="user-profile-address-list-create-address-img" 
                                src={PlusIcon} 
                                alt="" 
                                draggable={false}
                            />
                        </li>
                        {
                            addressContext.addresses.map(address => {
                                return (
                                    <UserProfileAddressCard 
                                        key={address.id}
                                        data={address} 
                                        onSetAsDefault={handleSetAsDefault}
                                        onDelete={handleDelete}
                                        onEdit={handleEditAddress}
                                    />
                                )
                            })
                        }
                    </>
                )
            }
            {
                addressContext?.addresses && addressContext?.addresses.length < 1 &&
                (
                    <div id="user-profile-address-list-empty">
                        <div id="user-profile-address-list-empty-adversiment">
                            <p id="user-profile-address-list-empty-adversiment-txt">
                                Nenhum endereço cadastrado
                            </p>
                            <p 
                                id="user-profile-address-list-empty-new-address-btn"
                                onClick={handleCreateAddress}
                            > 
                                Criar um endereço
                            </p>
                        </div>
                    </div>
                )
            }
        </ul>
    )
}