import React, {useState, useEffect, useContext} from "react"

import "./productCategory.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"
import ProductCard from "components/productCard/productCard"
import { useParams } from "react-router-dom"
import { apiCaller } from "api/apiCaller"

import Background from "assets/backgrounds/category-background-placeholder.svg"
import { AxiosError, AxiosResponse } from "axios"
import { GlobalContext } from "context/globalProvider"
import { IProduct } from "interfaces/product/IProduct"

export default function ProductCategory(){

    const globalContext = useContext(GlobalContext)

    const params = useParams()

    const [pathList, setPathList] = useState<string[]>([])
    const [products,       setProducts     ] = useState<IProduct[]>([])
    const [name, setName] = useState<string>("")

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("product-category-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])

    useEffect(() => {
        if (params.category){
            if (params.subcategory){
                let capitalizedCategory = params.category?.charAt(0).toUpperCase() + params.category?.slice(1)
                let capitalizedSubcategory = params.subcategory?.charAt(0).toUpperCase() + params.subcategory?.slice(1)
                
                console.log("sub", capitalizedSubcategory)
                let categoryWithoutPlural = capitalizedCategory.substring(0, capitalizedCategory.length - 1)

                setName(`${capitalizedSubcategory} De ${categoryWithoutPlural}`)

                const category = globalContext?.categories.find((category) => {
                    if (category.name === capitalizedCategory){
                        console.log(category)
                        return category
                    }
                })

                if (category) {
                    const subcategory = category.subcategories.find((subcategory) => {
                        if (subcategory.name === capitalizedSubcategory){
                            console.log(subcategory)
                            return subcategory
                        }
                    })

                    apiCaller.post(`/products/find?category=${category?.id}&subcategory=${subcategory?.id}`, {
                        enabled: true
                    }).then((response: AxiosResponse) => {
                        setProducts(response.data.data)
                    }).catch((error: AxiosError) => {
            
                    })
                }


            } else {
                let capitalized = params.category?.charAt(0).toUpperCase() + params.category?.slice(1)
                setName(capitalized)

                const category = globalContext?.categories.find((category) => {
                    if (category.name === capitalized){
                        console.log(category)
                        return category
                    }
                })

                apiCaller.post(`/products/find?category=${category?.id}`, {
                    enabled: true
                }).then((response: AxiosResponse) => {
                    setProducts(response.data.data)
                }).catch((error: AxiosError) => {
        
                })
            }
        }

    },[globalContext?.categories])

    function parsePath(path: string){
        const splitedPath = path.split("/")

        const list = []
        let fullPath = ""

        for (let index = 0; index < splitedPath.length; index++) {
            if (index !== 0) {
                fullPath = fullPath + "/" + splitedPath[index]
                list.push(fullPath)            
            } else {
                list.push("/") 
            }
        }
        setPathList(list)
    }

    return (
        <>  
            <Header/>
            <div id="product-category-template-main">
                <div id="product-category-template-content">
                    <div id="product-category-template-header">
                        <img 
                            id="product-category-template-header-img"
                            src={Background} 
                            alt="" 
                            draggable={false}
                        />
                        <div id="product-category-template-header-title">
                            <div className="product-category-template-header-title-spacer"/>
                            <p id="product-category-template-header-title-txt">
                                {name}
                            </p>
                            <div className="product-category-template-header-title-spacer"/>
                        </div>
                    </div>
                    <ul id="product-category-template-list" style={{minHeight: screenHeight}}>
                        {
                            products.map((product) => (
                                product.variations.map((variation) => {
                                    return (
                                        <ProductCard
                                            key={product.id + variation.id}
                                            data={{
                                                id: product.id + variation.id,
                                                name: product.name,
                                                url: product.url,
                                                description: product.description,
                                                enabled: product.enabled,
                                                featured: product.featured,
                                                categoryId: product.categoryId,
                                                subcategoryId: product.subcategoryId,
                                                variations: [variation]
                                            }}
                                        />
                                    )
                                })
                            ))
                        }
                    </ul>
                </div>
            </div>
            <Footer/>
        </>
    );
}