import React from "react"

import "./freight.css"

export default function Freight(){
    return (
        <div id="product-details-freight-main">
            <div id="product-details-freight-container">
                <p id="product-details-freight-label">
                    Cep
                </p>
                <div id="product-details-freight-input-container">
                    <input
                        placeholder="CEP"
                        id="product-details-freight-input" 
                        type="text" 
                    />
                    <div id="product-detail-freight-btn-container">
                        <p id="product-detail-freight-btn">
                            Calcular
                        </p>
                    </div>
                </div>
            </div>
            <div id="product-details-freight-info">

            </div>
        </div>
    )
}