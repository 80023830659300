import React, {useEffect, useState, useContext} from "react"

import "./size.css"

import { ISize }              from "interfaces/product/IProduct"
import { ProductPageContext } from "pages/productPage/productPage"

export default function Size(props: {data: ISize}){
    
    const productPageContext = useContext(ProductPageContext)
  
    const [selected, setSelected] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(true)

    useEffect(() => {
        if (!props.data.enabled){
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    },[props.data])

    useEffect(() => {
        if (productPageContext){
            if (props.data.id === productPageContext.size.id){
                setSelected(true)
            } else {
                setSelected(false)
            }
        }
    },[productPageContext?.size])

    function handleSizeSelect(){
        if (!disabled){
            if (productPageContext){
                let selectedSize = productPageContext.variation?.sizes.find((size) => {
                    if (size.id === props.data.id){
                        return size
                    }
                })
                if (selectedSize){
                    productPageContext.setSize(selectedSize)
    
                    if (props.data.id === productPageContext.size.id){
                        setSelected(true)
                    } else {
                        setSelected(false)
                    }
                }
            }

        }

    }
    
    function classSelector():string {
        if (disabled){
            return "product-details-size-element-main-disabled"
        } else {
            if (selected){
                return "product-details-size-element-main-selected"
            } else {
                return "product-details-size-element-main-unselected"
            }
        }
    }

    return (
        <li 
            id="product-details-size-element-main" 
            className={classSelector()}
            onClick={handleSizeSelect}>
            <p 
                id="product-details-size-element-txt"
                className={disabled?"product-details-size-element-txt-disabled":"product-details-size-element-txt-enabled"}
            >
                {props.data.size}
            </p>
        </li>
    )
}