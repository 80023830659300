import React, { useEffect, useState } from "react"

import { useNavigate } from "react-router-dom"
import Search from "assets/icons/search.png"

import "./searchField.css"

export default function SearchField(props: {placeholder: string, showIcon?: boolean}){

    const [value, setValue] = useState<string>("")

    const navigate = useNavigate()

    function handleSearchStringChange(value: string){
        setValue(value)
    }

    function handleSearch(){
        if (value === ""){
            navigate("/")
        } else {
            navigate(`/procurar/${value}`)
        }
    }

    return (
        <div id="search-field-main">
            <input 
                id="search-field-text-field" 
                placeholder={props.placeholder} 
                onChange={(e) => {handleSearchStringChange(e.target.value)}}
                value={value}    
            />
            {
                props.showIcon !== false &&
                (
                    <div id="search-field-text-field-icon-container" onClick={handleSearch}>
                        <img id="search-field-text-field-icon" src={Search} alt="" draggable={false}/>
                    </div>
                )
            }
        </div>
    )
}