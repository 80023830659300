import React, { useState, useContext } from "react"

import "./login.css"


import useExecute from "hooks/useExecute"
import { Link, useNavigate } from "react-router-dom"
import { GlobalContext } from "context/globalProvider"
import { AuthContext }       from "context/authProvider"
import { AccessContext }     from "pages/access/access"
import { apiCaller }         from "api/apiCaller"
import TextField             from "components/inputs/textfieldUnderline/textfieldUnderline"
import PasswordField         from "components/inputs/passwordfieldUnderline/passwordfieldUnderline"
import Logo                  from "assets/logo/logoTransparentBackground2.png"
import { AxiosError, AxiosResponse } from "axios"

export default function Login(props: {}){

    const globalContext = useContext(GlobalContext)
    const authContext   = useContext(AuthContext)
    const accessContext = useContext(AccessContext)

    const navigate = useNavigate()

    const [email,    setEmail   ] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error,    setError   ] = useState<string>("")

    useExecute(4.6, error, handleResetError)

    function handleRecoverBtnClick(){
        accessContext?.navigate("recover")
    }

    function handleRegisterBtnClick(){
        accessContext?.navigate("register")
    }

    function handleEmailChange(value: string) {
        setEmail(value);
    }

    function handlePasswordChange(value: string) {
        setPassword(value);
    }

    function handleResetError(){
        setError("")
    }

    function handleLogin () {
        apiCaller.post("/auth/login", {
            email: email,
            password: password
        }).then((response: AxiosResponse) => {
            console.log(response.data.isAdmin)
            authContext?.setToken(response.data.data)
            authContext?.setAuthenticated(response.data.isAdmin)

            apiCaller.get("/accounts/", {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                globalContext?.setUser(response.data.data)
                console.log(response.data.data)
            }).catch((error: AxiosError) => {
                // authContext?.rmToken()
            })

            navigate("/")
        }).catch((error: AxiosError) => {

            const statusCode = error.response?.status

            if (statusCode === 400){
                accessContext?.setUser({
                    email: email,
                    password: password
                })
                accessContext?.navigate("confirm")
            } 
            else if (statusCode === 401) {
                setError("Email ou senha inválido")
            }
        });
    }

    return (
            <div id="login-container">
                <div id="login-logo-container" >
                    <Link id="login-logo-link" to={"/"} draggable={false}>
                        <img id="login-logo" src={Logo} alt="" draggable={false}/>
                    </Link>
                </div>
                <div id="login-content">
                    <div className="login-input-container">
                        <p id="login-text">
                            Conecte-se
                        </p>
                    </div>
                    <div className="login-input-container">
                        <TextField
                            label="E-mail"
                            onValueChange={handleEmailChange}
                            onSelected={handleResetError}
                        />
                    </div>
                    <div className="login-input-container">
                        <PasswordField
                            label="Senha"
                            onValueChange={handlePasswordChange}
                            onSelected={handleResetError}
                            onKeyDown={handleLogin}
                        />
                    </div>  
                    <div id="login-warning">
                        {
                            error &&
                            (
                                <p id="login-warning-txt">
                                    {error}
                                </p>
                            )
                        }
                    </div>                   
                    <div id="login-input-container-btn">
                        <div id="login-input-container-btn-login" onClick={handleLogin}>
                            <p id="login-input-container-btn-login-txt">
                                Entrar
                            </p>
                        </div>
                        <p id="login-input-container-btn-forgot-password" onClick={handleRecoverBtnClick}>
                            Esqueceu sua senha?
                        </p>
                        <p id="login-input-container-btn-register" onClick={handleRegisterBtnClick}>
                            Criar conta
                        </p>
                    </div>
                </div>
            </div>
    )
}