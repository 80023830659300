import React, {useEffect, useState} from "react"

import "./checkbox.css"

import Check from "assets/icons/check.png"

export default function CheckBox(
    props: {
        text: string, 
        value?: boolean, 
        disabled?: boolean, 
        onValueChange: Function
        onClick?: Function
    }
){
    
    const [check, setCheck] = useState<boolean>(false)
    
    useEffect(() => {
        setCheck(props.value || false)
    },[props.value])

    function handleCheck(){
        if (props.onClick){
            props.onClick(true)
        }
        if (props.disabled === undefined || props.disabled === false){
            props.onValueChange(!check)
            setCheck(!check)
        }
    }

    return (
        <div id="checkbox-main">
            <div id="checkbox-selection">
                <div id="checkbox-selection-box" onClick={handleCheck}>
                    {   
                        check &&
                        (
                            <img 
                                id="checkbox-selection-box-img"
                                src={Check}
                                alt="" 
                            />
                        )
                    }
                </div>
            </div>
            <div id="checkbox-label">
                <p id="checkbox-label-txt">
                    {props.text}
                </p>
            </div>
        </div>
    )
}