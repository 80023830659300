import React from "react"

import "./option.css"

export default function Option(props: {value: string, fontSize?: string, onSelect: Function}){

    function handleOptionSelect(){
        props.onSelect(props.value)
    }

    return (
        <div id="basic-dropdown-option-main" onClick={handleOptionSelect}>
            <p id="basic-dropdown-option-value" style={props.fontSize !== ""? {fontSize: props.fontSize}: {}}>
                {props.value}
            </p>
        </div>
    )
}