import React from "react"
import { Route, BrowserRouter, Routes} from "react-router-dom"

import UserProtectedRoute from "routes/userProtectedRoute"
import AdminProtectedRoute from "routes/adminProtectedRoute"
import ScrollToTop             from "components/scrollToTop/scrollToTop"

import NotFound from "pages/notFound/notFound"
import Maintenance from "pages/maintenance/maintenance"
import Recover from "pages/recover/recover"

import Catalog                 from "pages/catalog/catalog"
import Access                  from "pages/access/access"
import ProductCategory         from "pages/productCategory/productCategory"
import ProductPage             from "pages/productPage/productPage"
import Search                  from "pages/search/search"

import UserMenu                from "pages/user/menu/userMenu"
import UserAddress             from "pages/user/address/userAddress"
import UserOrders              from "pages/user/orders/userOrders"
import UserCart                from "pages/user/cart/userCart"
import UserPersonalData        from "pages/user/personalData/userPersonalData"

import AdminMenu               from "pages/admin/menu/adminMenu"
import AdminProductList        from "pages/admin/product/productList/productList"
import AdminProduct            from "pages/admin/product/product/product"
import AdminPaidOrders         from "pages/admin/orders/paid/paid"
import AdminReadyOrders        from "pages/admin/orders/ready/ready"
import AdminShippedOrders      from "pages/admin/orders/shipped/shipped"
import AdminSettings           from "pages/admin/settings/settings"
import AdminArchivedOrders     from "pages/admin/orders/archived/archived"


export default function PageRoutes() {
    return (
        <BrowserRouter basename="/">
            <ScrollToTop/>
            <Routes>
                {/* <Route element={<AdminProtectedRoute/>}> */}
                    <Route element={<AdminMenu/>}           path="/admin"/>
                    <Route element={<AdminSettings/>}       path="/admin/configuracoes"/>
                    <Route element={<AdminProductList/>}    path="/admin/produtos"/>
                    <Route element={<AdminProduct/>}        path="/admin/produtos/novo"/>
                    <Route element={<AdminPaidOrders/>}     path="/admin/pedidos-pagos"/>
                    <Route element={<AdminReadyOrders/>}    path="/admin/pedidos-prontos"/>
                    <Route element={<AdminShippedOrders/>}  path="/admin/pedidos-enviados"/>
                    <Route element={<AdminArchivedOrders/>} path="/admin/pedidos-arquivados"/>
                {/* </Route> */}
                <Route element={<UserProtectedRoute/>}>
                    <Route element={<UserMenu/>}            path="/perfil"/>
                    <Route element={<UserAddress/>}         path="/perfil/enderecos"/>
                    <Route element={<UserOrders/>}          path="/perfil/pedidos"/>
                    <Route element={<UserPersonalData/>}    path="/perfil/dados"/>
                </Route>
                <Route element={<Catalog/>}                 path="/"/>
                <Route element={<UserCart/>}                path="/carrinho"/>
                <Route element={<Access/>}                  path="/login"/>
                <Route element={<ProductPage/>}             path="/produtos/:url?"/>
                <Route element={<Recover/>}                 path="/recuperar/:url"/>
                <Route element={<Search/>}                  path="/procurar/:searchstring"/>
                <Route element={<ProductCategory/>}         path="/categoria/:category/:subcategory?"/>
                <Route element={<NotFound/>}                path="/*"/>
            </Routes>
        </BrowserRouter>
    )
}