import React, { useState, useEffect, useContext, createContext, Dispatch } from "react"

import "./userAddress.css"

import UserProfileTemplate from "../components/userPageTemplate/userPageTemplate"
import UserProfileAddressList from "./components/userProfileAddressList/userProfileAddressList"
import UserProfileAddressNew from "./components/userProfileAddressNew/userProfileAddressNew"
import UserProfileAddressEdit from "./components/userProfileAddressUpdate/userProfileAddressUpdate"
import IShipmentAddress from "interfaces/IShipmentAddress"
import Address from "assets/icons2/hand-package-without-background.png"
import { AuthContext } from "context/authProvider"

interface INavigation {
    addressList: boolean
    createAddress: boolean
    updateAddress: boolean
}

interface IAddressContext {
    navigate: Function
    addresses: IShipmentAddress[]
    setAddresses: Dispatch<React.SetStateAction<IShipmentAddress[]>>
    addressToEdit: IShipmentAddress
    setAddressToEdit: Dispatch<React.SetStateAction<IShipmentAddress>>
}

export const AddressContext = createContext<IAddressContext | undefined>(undefined);

export default function UserAddress() {

    const authContext = useContext(AuthContext)

    const [addresses, setAddresses] = useState<IShipmentAddress[]>([])
    const [addressToEdit, setAddressToEdit] = useState<IShipmentAddress>({
        id: "",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })

    const [navigationController, setNavigationController] = useState<INavigation>({
        addressList: true,
        createAddress: false,
        updateAddress: false
    })


    function handleNavigation(value: string) {
        for (const key in navigationController) {
            if (navigationController.hasOwnProperty(key)) {
                const keyTyped = key as keyof INavigation

                if (keyTyped === value) {
                    setNavigationController((prev) => ({
                        ...prev,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((prev) => ({
                        ...prev,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    return (
        <AddressContext.Provider 
            value={{
                navigate: handleNavigation,
                addresses: addresses,
                setAddresses: setAddresses,
                addressToEdit: addressToEdit,
                setAddressToEdit: setAddressToEdit
            }}
        >
            <UserProfileTemplate key={"address"} image={Address} name="Endereços" navigationUrl="/perfil/enderecos">
                {
                    navigationController.addressList &&
                    (
                        <UserProfileAddressList/>
                    )
                }
                {
                    navigationController.createAddress &&
                    (
                        <UserProfileAddressNew/>
                    )
                }
                {
                    navigationController.updateAddress && addressToEdit &&
                    (
                        <UserProfileAddressEdit/>
                    )
                }
            </UserProfileTemplate>
        </AddressContext.Provider>
    );
}