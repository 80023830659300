import { createHash } from "crypto";

async function createHashString(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

async function verifyHash(data: string, toCompare: string) {
    const hash = await createHashString(data);
    return hash === toCompare;
}

async function checkIsAdmin(data: string) {
    if (process.env.REACT_APP_ADMIN_ID) {
        const adminDefault = await createHashString(process.env.REACT_APP_ADMIN_ID);
        const isAdmin = await verifyHash(data, adminDefault);
        return isAdmin;
    }

    return false;
}

export { createHashString, verifyHash, checkIsAdmin };