import React, { useState, useEffect, useContext } from "react"

import "./userProfileAddressUpdate.css"

import IShipmentAddress   from "interfaces/IShipmentAddress"
import Dropdown           from "components/inputs/dropdownUnderline/dropdownUnderline"
import apiIBGEStates      from "api/apiIBGEStates"
import apiIBGECity        from "api/apiIBGECity"
import IState             from "interfaces/address/IState"
import ICity              from "interfaces/address/ICity"
import { apiCaller } from "api/apiCaller"
import { AuthContext } from "context/authProvider"
import { AddressContext } from "pages/user/address/userAddress"
import { AxiosError, AxiosResponse } from "axios"
import TextField from "components/inputs/textfieldUnderline/textfieldUnderline"

interface IShipmentAddressError {
    name:       string;
    address1:    string;
    address2:   string;
    unitNumber: string;
    complement: string;
    city:       string;
    state:      string;
    postalCode: string;
}

export default function UserProfileAddressEdit(props: {}){

    const authContext = useContext(AuthContext)
    const addressContext = useContext(AddressContext)

    const [stateFetchError, setStateFetchError] = useState<boolean>(false)
    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)
    const [error, setError] = useState<IShipmentAddressError>({
        name:       "",
        address1:    "",
        address2:   "",
        unitNumber: "",
        complement: "",
        city:       "",
        state:      "",
        postalCode: "",
    })

    const [address, setAddress] = useState<IShipmentAddress>({
        id: "",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })
   
    const [stateData, setStateData] = useState<IState[]>([])
    const [cityData, setCityData] = useState<ICity[]>([])


    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        apiIBGEStates()
            .then(mappedData => {
                setStateData(mappedData);
            })
            .catch(error => {
                setStateFetchError(true)
                console.error("api error", error);
            });
    }, []);

    useEffect(() => {
        if (!stateFetchError){
            apiIBGECity(address.state)
            .then(mappedData => {
                setCityData(mappedData);
            })
            .catch(error => {
                console.error("api error", error);
            });
        }
    },[address.state])

    useEffect(() => {
        if (addressContext){
            setAddress(addressContext?.addressToEdit)
        }
    },[addressContext])
   
    function handleCancel(){
        addressContext?.navigate("addressList")
    }

    function handleFieldChange(field: string, value: string | number) {

        if (field === "state"){
            setAddress((prev) => ({
                ...prev,
                city: ""
            }))
        }

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress

                if (keyTyped === field){
                    setAddress((previous) => ({
                        ...previous,
                        [keyTyped]: value
                    }))
                }
            }
        }
    }

    function handleSave(){
        let error = false
       

        if (!error) {
            console.log(address)
            const token = `Bearer ${authContext?.getToken()}`

            apiCaller.put(`/addresses/${address.id}`, {
                name: address.name,
                address1: address.address1,
                address2: address.address2,
                unitNumber: address.unitNumber,
                complement: address.complement,
                city: address.city,
                state: address.state,
                postalCode: parseInt(address.postalCode),
                default: address.default
            }, {
                headers: {
                    "Authorization": token
                }
            }).then((response: AxiosResponse) => {
                addressContext?.navigate("addressList")
    
            }).catch((error: AxiosError) => {
                console.log(error)
                addressContext?.navigate("addressList")
            });

        }
    }

    

    return (
        <div id="user-profile-address-update">
            <div id="user-profile-address-update-label">
                <p id="user-profile-address-update-label-txt">
                </p>
            </div>
            <div id="user-profile-address-update-fields">
                <div className="user-profile-address-update-fields-container">
                    <div className="user-profile-address-update-fields">
                        <TextField
                            label="Nome"
                            value={addressContext?.addressToEdit.name}
                            onValueChange={(value: string) => {handleFieldChange("name", value)}}
                        />
                    </div>
                    <div className="user-profile-address-update-fields">
                        <TextField
                            label="Endereço"
                            value={addressContext?.addressToEdit.address1}
                            onValueChange={(value: string) => {handleFieldChange("address1", value)}}
                        />
                    </div>
                    <div className="user-profile-address-update-fields">
                        <div className="user-profile-address-update-sub-fields">
                            <TextField
                                label="Número"
                                value={`${addressContext?.addressToEdit.unitNumber}`}
                                onValueChange={(value: number) => {handleFieldChange("unitNumber", value)}}
                            />
                        </div>
                        <div className="user-profile-address-update-sub-fields">
                            <TextField
                                label="Complemento"
                                value={addressContext?.addressToEdit.complement}
                                onValueChange={(value: string) => {handleFieldChange("complement", value)}}
                            />
                        </div>
                    </div>
                    <div className="user-profile-address-update-fields">
                        <TextField
                            label="Bairro"
                            value={addressContext?.addressToEdit.address2}
                            onValueChange={(value: number) => {handleFieldChange("address2", value)}}
                        />
                    </div>
                </div>
                <div className="user-profile-address-update-fields-container">
                    <div className="user-profile-address-update-fields">
                        {   
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Estado"
                                    showOnClick={true}
                                    options={stateData.map(state => {return state.abbreviation})}
                                    onSelect={(value: string) => {handleFieldChange("state", value)}}
                                    default={address.state}
                                />
                            )
                        }
                        {   
                            stateFetchError &&
                            (
                                <TextField
                                    label="Estado"
                                    value={address.state}
                                    onValueChange={(value: string) => {handleFieldChange("state", value)}}
                                />
                            )
                        }
                    </div>
                    <div className="user-profile-address-update-fields">
                        {
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Cidade"
                                    showOnClick={true}
                                    options={cityData.map(city => {return city.name})}
                                    onSelect={(value: string) => {handleFieldChange("city", value)}}
                                    default={address.city}
                                />
                            )
                        }
                        {
                            stateFetchError &&
                            (
                                <TextField
                                    label="Cidade"
                                    value={address.city}
                                    onValueChange={(value: string) => {handleFieldChange("city", value)}}
                                />
                            )
                        }
                    </div>
                    <div className="user-profile-address-update-fields">
                        <TextField
                            label="CEP"
                            value={address.postalCode}
                            onValueChange={(value: string) => {handleFieldChange("postalCode", value)}}
                            maxCharacters={8}
                            numeric={true}
                        />
                    </div>
                    <div id="user-profile-address-update-btns-container">
                        <p className="user-profile-address-update-btns-save" onClick={handleSave}>
                            Salvar
                        </p>
                        <p className="user-profile-address-update-btns" onClick={handleCancel}>
                            Cancelar
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}