import React, {useEffect, useState, useRef} from "react"

import "./passwordfieldUnderline.css"

import passwordHideAnimation from "assets/animations/password-hide.json"
import Lottie                from "lottie-react"



export default function PasswordField(
    props: {
        label: string, 
        error?: string,
        value?: string
        placeholder?: string,
        fontSize?: string,
        labelFontSize?: string,
        password?: boolean,
        onValueChange: Function,
        onEdit?: Function,
        onSelected?: Function,
        onKeyDown?: Function
    }
){

    const lottieRef = useRef<any>();

    const [startAnimation, setStartAnimation] = useState<boolean>(false)
    const [type, setType] = useState<string>("password")
    const [value, setValue] = useState<string>("")
    const [selected, setSelected] = useState<boolean>(false)

    useEffect(() => {
        setValue(props.value || "")
    },[props.value])

    useEffect(() => {
        lottieRef.current.setSpeed(4)
        lottieRef.current.playSegments([0,18], true)
    },[])


    function handleEnableValueChange(){
        if (props.onEdit){
            props.onEdit()
        }
    }

    function handleValueChange(value: string){
        setValue(value)
        props.onValueChange(value)
    }

    function handleSelected(){
        setSelected(true)
    }

    function handleUnselect(){
        setSelected(false)
    }

    function handleClick(){
        if (props.onSelected){
            props.onSelected()
        }
    }

    function handleShowPassword(){
        if (type === "password"){
            lottieRef.current.play()
            lottieRef.current.setDirection(-1)
            setType("text")
        } else {
            lottieRef.current.play()
            lottieRef.current.setDirection(1)
            setType("password")
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>){
        if (event.key === "Enter"){ 
            if (props.onKeyDown){
                props.onKeyDown()
            }
        }
    }

    return (
        <div id="password-field-underline-main" className={(selected? "password-field-underline-main-selected":"password-field-underline-main")}>
            <div id="password-field-underline-text-label-container">
                <p id="password-field-underline-text-label" style={props.labelFontSize !== ""? {fontSize: props.labelFontSize}: {}}>
                    {props.label}
                </p>    
               {    
                    props.onEdit &&
                    ( 
                        <p id="password-field-underline-text-edit" onClick={handleEnableValueChange}>
                            Editar
                        </p>
                    )
                }
            </div>
            <div id="password-field-underline-text-info">
                <input 
                    id="password-field-underline-text-input" 
                    type={type} 
                    onChange={(e) => {handleValueChange(e.target.value)}} 
                    value={value}
                    placeholder={props.placeholder}
                    onFocus={handleSelected}
                    onBlur={handleUnselect}
                    onMouseDown={handleClick}
                    onKeyDown={(event) => {handleKeyDown(event)}}
                />
                <div id="password-field-underline-show-btn" onClick={handleShowPassword}>
                    <Lottie
                        animationData={passwordHideAnimation}
                        lottieRef={lottieRef}
                        autoplay={false}
                        loop={false}
                        style={{width: "50%", marginTop: "15%"}}
                    />
                </div>
            </div>
        </div>
    )
}