export default class TimeDateParser {
    static parseDate(day: number, month: number, year: number){
        var parsedDay
        var parsedMonth

        if (day < 10){
            parsedDay =`0${day}`
        } else {
            parsedDay = day
        }

        if (month < 10){
            parsedMonth =`0${month}`
        } else {
            parsedMonth = month
        }

        return `${parsedDay}/${parsedMonth}/${year}`
    }

    static parseTime(hour: number, minute: number){

        var parsedHour
        var parsedMinute

        if (hour === 0){
            parsedHour = "00"
        }
        else if (hour < 10){
            parsedHour =`0${hour}`
        }
        else {
            parsedHour = hour
        }

        if (minute === 0){
            parsedMinute = "00"
        }
        else if (hour < 10){
            parsedMinute =`0${minute}`
        }
        else {
            parsedMinute = minute
        }

        return `${parsedHour}:${parsedMinute}`
    }
}
