import React from "react"
import {Link} from "react-router-dom"

import "./footerLink.css"

export default function FooterLink(props: {name: string, link: string}){
    return (
        <div 
            id="footer-link-main">
            <Link  
                id="footer-link-txt"
                to={props.link}
            >
                {
                    props.name
                }
            </Link>
        </div>
    )
}