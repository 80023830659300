import React, {useContext, useEffect, useState} from "react"

import "./userProfileAddressCard.css"

import DefaultAddressIcon from "assets/icons/location.png"
import IShipmentAddress from "interfaces/IShipmentAddress"

export default function UserProfileAddressCard(
    props: {
        data: IShipmentAddress, 
        onSetAsDefault: Function, 
        onDelete: Function,
        onEdit: Function
    }
){


    function handleSetAsDefault(){
        props.onSetAsDefault(props.data.id)
    }

    function handleDelete(){
        props.onDelete(props.data.id)
    }
    
    function handleUpdate(){
        props.onEdit(props.data.id)
    }

    return (
        <li id="user-profile-address-card-main">
            <div id="user-profile-address-card-header">
                <div id="user-profile-address-card-header-name">
                    <p id="user-profile-address-card-header-name-txt"> 
                        {props.data.name}
                    </p>
                </div>
                <div id="user-profile-address-card-header-default-img-container">
                    <img
                        style={{display: (props.data.default? "flex": "none")}}
                        id="user-profile-address-card-header-default-img" 
                        src={DefaultAddressIcon}
                        alt="" 
                        draggable={false}
                    />
                </div>
            </div>
            <div id="user-profile-address-card-body">
                <div className="user-profile-addres-card-body-data-container">
                    <div className="user-profile-address-card-body-data-subcontainer">
                        <p className="user-profile-address-card-body-data-title-txt">
                            Endereço
                        </p>
                        <p className="user-profile-address-card-body-data-txt">
                            {props.data.address1}
                        </p>
                    </div>
                </div>
                <div className="user-profile-addres-card-body-data-container2">
                    <div className="user-profile-address-card-body-data-subcontainer">
                        <p className="user-profile-address-card-body-data-title-txt">
                            Número
                        </p>
                        <p className="user-profile-address-card-body-data-txt">
                            {props.data.unitNumber}
                        </p>
                    </div>
                    {
                        props.data.complement !== "" &&
                        (
                            <div className="user-profile-address-card-body-data-subcontainer user-profile-address-card-body-data-subcontainer-spacer">
                                <p className="user-profile-address-card-body-data-title-txt">
                                    Complemento
                                </p>
                                <p className="user-profile-address-card-body-data-txt">
                                    {props.data.complement}
                                </p>
                            </div>
                        )
                    }
                 
                </div>
                <div className="user-profile-addres-card-body-data-container2">
                    <div className="user-profile-address-card-body-data-subcontainer">
                        <p className="user-profile-address-card-body-data-title-txt">
                            Bairro
                        </p>
                        <p className="user-profile-address-card-body-data-txt">
                            {props.data.address2}
                        </p>
                    </div>
                </div>
                <div className="user-profile-addres-card-body-data-container2">
                    <div className="user-profile-address-card-body-data-subcontainer">
                        <p className="user-profile-address-card-body-data-title-txt">
                            Cidade
                        </p>
                        <p className="user-profile-address-card-body-data-txt">
                            {props.data.city}
                        </p>
                    </div>
                </div>
                <div className="user-profile-addres-card-body-data-container2">
                    <div className="user-profile-address-card-body-data-subcontainer">
                        <p className="user-profile-address-card-body-data-title-txt">
                            Estado
                        </p>
                        <p className="user-profile-address-card-body-data-txt">
                            {props.data.state}
                        </p>
                    </div>
                    <div className="user-profile-address-card-body-data-subcontainer user-profile-address-card-body-data-subcontainer-spacer">
                        <p className="user-profile-address-card-body-data-title-txt">
                            CEP
                        </p>
                        <p className="user-profile-address-card-body-data-txt">
                            {props.data.postalCode}
                        </p>
                    </div>
                </div>
            </div>
            <div id="user-profile-address-card-footer">
                <p 
                    className="user-profile-address-card-footer-btns"
                    onClick={handleUpdate}
                >
                    Alterar
                </p>
                <p 
                    className="user-profile-address-card-footer-btns"
                    onClick={handleDelete}
                >
                    Excluir
                </p>
                {
                    !props.data.default &&
                    (
                        <p 
                            className="user-profile-address-card-footer-btns" 
                            onClick={handleSetAsDefault}
                        >
                            Definir como padrão
                        </p>

                    )
                }
            </div>
        </li>
    )
}