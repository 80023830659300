import React, {useEffect, useState} from "react"

import "./checkboxWithoutText.css"

import Check from "assets/icons/check.png"

export default function CheckBoxWithoutText(
    props: {
        value: boolean, 
        disabled?: boolean, 
        onValueChange: Function
        onTryValueChange?: Function
    }){
    
    const [check, setCheck] = useState<boolean>(false)
    
    useEffect(() => {
        setCheck(props.value || false)
    },[props.value])


    function handleCheck(){
        if (props.disabled === undefined || props.disabled === false){
            props.onValueChange(!check)
            setCheck(!check)
        }
        if (props.disabled === true){
            if (props.onTryValueChange){
                props.onTryValueChange()
            }
        }
    }

    return (
        <div id="checkbox-without-text-main">
            <div id="checkbox-without-text-selection">
                <div id="checkbox-without-text-selection-box" onClick={handleCheck}>
                    {   
                        check &&
                        (
                            <img 
                                id="checkbox-without-text-selection-box-img"
                                src={Check}
                                alt="" 
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}