import React, { useEffect, useState } from "react"

import "./settings.css"

import AdminPageTemplate   from "pages/admin/components/adminPageTemplate/adminPageTemplate"
import CategoryPage        from "./components/categoryPage/categoryPage"
import MainPage from "./components/mainPage/mainPage"

interface INavigation {
    categoryPage: boolean
    mainPage: boolean
}

export default function AdminSettings(props: {}){

    const [navigationController, setNavigationController] = useState<INavigation>({
        categoryPage: true,
        mainPage: false
    })

    function handleNavigation(element: string){
        for (const key in navigationController){
            if (navigationController.hasOwnProperty(key)){
                const keyTyped = key as keyof INavigation

                if (keyTyped === element){
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    return (
        <AdminPageTemplate navigationUrl="/admin/configuracoes">
            <div id="admin-settings-main">
                <ul id="admin-settings-menu">
                    <li className="admin-settings-menu-entry">
                        <p className="admin-settings-menu-entry-txt" onClick={() => {handleNavigation("categoryPage")}}>
                            Categoria 
                        </p>
                    </li>
                    <li className="admin-settings-menu-entry">
                        <p className="admin-settings-menu-entry-txt" onClick={() => {handleNavigation("mainPage")}}>
                            Pagina Principal 
                        </p>
                    </li>
                </ul>
                <div id="admin-settings-content">
                    {   
                        navigationController.categoryPage &&
                        (
                            <CategoryPage/>
                        )
                    }
                    {
                        navigationController.mainPage &&
                        (
                            <MainPage/>
                        )
                    }
                </div>
            </div>
        </AdminPageTemplate>
    )
}