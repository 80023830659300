import React from "react"
import { Link } from "react-router-dom"

import "./unauthenticated.css"

import unauthenticatedIcon from "assets/icons/user.png"

export default function Unauthenticated(){
    return (
        <div id="header-unauthenticated-main">
            <Link 
                id="header-unauthenticated-menu-btn" 
                to={"/login"} 
                draggable={false}
            >
                <div id="header-unauthenticated-menu-btn-txt-container">
                    <p id="header-unauthenticated-menu-btn-txt">
                        Faça o login
                    </p>
                </div>
                <img 
                    id="header-unauthenticated-menu-icon"
                    src={unauthenticatedIcon}
                    alt="" 
                />
            </Link>
        </div>
    )
}