import React from "react"

import "./variation.css"

import { IProductVariation } from "interfaces/product/IProduct"

import DiscountIcon from "assets/icons/discount.png"

export default function Option(props: {data: IProductVariation}){
    return (
        <>
            <li
                id="admin-product-list-product-variation-selector-variation-main" 
                style={{backgroundColor: `#${props.data.color}`}}
            >
            </li>
        </>
    )
}