import React, { useEffect, useState, useId } from "react"

import "./headerMenu.css"

import HeaderMenuEntry from "./components/headerMenuEntry"
import { ISubcategory } from "interfaces/product/IProduct"

export default function HeaderMenu(
    props: {
        categoryName: string, 
        hoverColor?: string,
        baseUrl?: string, 
        entries?: ISubcategory[]
    }
){

    const elementId = useId()

    const [width,          setWidth         ] = useState<number>(0)
    const [headerHover,    setHeaderHover   ] = useState<boolean>(false)
    const [bodyHover,      setBodyHover     ] = useState<boolean>(false)
    const [renderDropDown, setRenderDropDown] = useState<boolean>(false)

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [position, setPosition] = useState<any>({positionX: 0, positionY: 0})

    useEffect(() => {
        setRenderDropDown(false)
    }, [windowDimensions]);
    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    useEffect(() => {
        const elemento = document.getElementById(`header-menu-${elementId}-btn`);
        if (elemento != null) {
            const rect = elemento.getBoundingClientRect();
            setWidth(rect.width * 1)
            setPosition({
                X: rect.left,
                Y: rect.top + rect.height 
            })
        }
        
    }, [window.innerWidth,window.innerHeight])
    
    useEffect(() => {
        if (headerHover || bodyHover){
            setRenderDropDown(true)
        } else {
            setRenderDropDown(false)
        }
    }, [bodyHover, headerHover])

    function handleCartHover(element: string){
        if (element === "header"){
            setHeaderHover(true)
        } 
        if (element === "body"){
            setBodyHover(true)
        }
    }

    function handleCartUnhover(element: string){
        if (element === "header"){
            setHeaderHover(false)
        } 
        if (element === "body"){
            setBodyHover(false)
        }
    }

    return (
        <div id="header-menu-main">
            <a 
                href={`/categoria/${props.categoryName.toLowerCase()}`}
                id={`header-menu-${elementId}-btn`}
                className="header-menu-btn"
                draggable={false}
                onMouseEnter={() => {handleCartHover("header")}}
                onMouseLeave={() => {handleCartUnhover("header")}}
            >  
                <p id="header-menu-btn-txt">
                    {props.categoryName}
                </p>
            </a>
            <ul id="header-menu-list" 
                style={{display: renderDropDown? "flex": "none", top: position.Y, left: position.X, width: width}}
                onMouseEnter={() => {handleCartHover("body")}}
                onMouseLeave={() => {handleCartUnhover("body")}}
            >
                {
                    props.entries?.map((entry) => {
                        return (
                            <HeaderMenuEntry
                                key={entry.id}
                                data={entry.name}
                                parentCategory={props.categoryName}
                                hoverColor={props?.hoverColor || ""}
                            />
                        )
                    })
                }
            </ul>
        </div>
    )
}